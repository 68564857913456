<template>
  <div
    style="
      display: flex;
      max-height: 100vh;
      max-width: 100vw;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    "
  >
    <img
      alt="image"
      :src="sequenceData?.frames?.[groupService?.frameIndex || 0]?.frame_url"
      style="display: none"
      ref="canvasBackgroundImage"
    />
    <div class="flex flex-1 flex-col">
      <TopBar :groupService="groupService" :query="route.query" />

      <div ref="canvasWrapper" class="flex-1">
        <canvas id="c"></canvas>
      </div>

      <FrameNavigator :groupService="groupService" :query="route.query" />
    </div>
    <Sidebar :canvasService="canvasService" :groupService="groupService" />
  </div>
  <ContextMenu
    v-if="canvasService && groupService"
    :canvasService="canvasService"
    :groupService="groupService"
  />
  <Crosshair v-if="canvasService" :canvasService="canvasService" />
  <img
    v-if="groupService?.isSequenceSaving"
    :src="saveBackground"
    class="fixed top-0 left-0 w-screen h-screen"
  />
</template>

<script lang="ts" setup>
import * as fabric from "fabric";
import { ref, onMounted, markRaw, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import saveBackground from "@/assets/imgs/editorCursor.png";
import SequenceRepository from "@/repositories/SequenceRepository";
import { Sequence } from "@/views/person_gad/types";
import ContextMenu from "./components/ContextMenu.vue";
import Crosshair from "./components/Crosshair.vue";
import FrameNavigator from "./components/FrameNavigator.vue";
import Sidebar from "./components/Sidebar.vue";
import TopBar from "./components/TopBar.vue";
import CanvasService from "./services/canvasService";
import GroupService from "./services/groupService";

const route = useRoute();

const canvasObject = ref<fabric.Canvas>();
const canvasWrapper = ref<HTMLElement>();
const canvasBackgroundImage = ref<HTMLImageElement>();
const canvasService = ref<CanvasService>();
const groupService = ref<GroupService>();
const sequenceData = ref<Sequence>();

onMounted(async () => {
  sequenceData.value = await SequenceRepository.loadSequenceAnnotations(route.params.id as string);
  const backgroundImage = canvasBackgroundImage.value;
  if (!backgroundImage) {
    return;
  }

  backgroundImage.onload = async () => {
    await initCanvas(backgroundImage);
  };
});

onUnmounted(async () => {
  if (groupService.value) {
    await groupService.value.saveAnnotations(undefined, true);
    groupService.value.dispose();
  }

  if (canvasService.value) {
    await canvasService.value.dispose();
  }
});

const initCanvas = async (image: HTMLImageElement) => {
  if (canvasService.value) {
    canvasService.value.rerender();
    return;
  }

  if (!sequenceData.value) {
    return;
  }

  const canvas = new fabric.Canvas("c", {
    width: canvasWrapper.value?.clientWidth || 0,
    height: canvasWrapper.value?.clientHeight || 0,
    backgroundColor: "#f3f3f3",
    data: {},
    preserveObjectStacking: true,
  });

  canvas.setCursor("pointer");

  const canvasServiceInstance = markRaw(new CanvasService(canvas));

  canvasServiceInstance.addMouseEventsFeature();
  canvasServiceInstance.addMouseWheelFeature();
  canvasServiceInstance.addContextMenuFeature();
  canvasServiceInstance.addKeyboardEventsFeatures();
  canvasServiceInstance.setCanvasSize(image);

  const groupServiceInstance = markRaw(new GroupService(canvasServiceInstance, sequenceData.value));
  groupServiceInstance.addKeyboardFeatures();

  canvasService.value = canvasServiceInstance;
  groupService.value = groupServiceInstance;
  canvasObject.value = markRaw(canvas);
};
</script>
