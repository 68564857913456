<template>
  <div class="flex w-full overflow-hidden h-screen">
    <div class="relative flex-1 pb-20 overflow-auto">
      <div class="sticky top-0 z-10 bg-white border-b border-gray-300">
        <div class="flex gap-4 px-24 py-4">
          <div>
            <p class="mb-1">Camera</p>
            <OaiCombobox
              v-model="streamFilter"
              :options="
                streams.map((stream) => ({
                  name: `${stream.customer_name}/${stream.site_id} (${stream.camera_id})`,
                  value: stream,
                }))
              "
              :placeholder="
                streamFilter.length ? `Selected ${streamFilter.length} cameras` : 'Select camera'
              "
              multiple
              :min-width="240"
            />
          </div>

          <div>
            <p class="mb-1">Process</p>
            <OaiCombobox
              :options="
                processClasses.map((process) => ({
                  name: process.decodedLabel,
                  value: process.decodedLabel,
                }))
              "
              :placeholder="
                processFilter.length
                  ? `Selected ${processFilter.length} processes`
                  : 'Select process'
              "
              v-model="processFilter"
              multiple
              :min-width="240"
            />
          </div>

          <div>
            <p class="mb-1">Date</p>
            <VueDatePicker
              v-model="datePickerRange"
              :columns="4"
              :enable-time-picker="false"
              placeholder="Select date range"
              format="dd.MM.yyyy"
              class="text-xs"
              locale="en"
              auto-apply
              range
            />
          </div>

          <div>
            <p class="mb-1">Status</p>
            <OaiListbox
              :minWidth="150"
              :options="[
                { name: 'All', value: null },
                { name: 'Completed', value: 'completed' },
                { name: 'Approved', value: 'approved' },
                { name: 'Not completed', value: 'incomplete' },
              ]"
              v-model="statusFilter"
            />
          </div>

          <div class="ml-4">
            <p class="mb-1">Sort by</p>
            <div class="flex items-center gap-5">
              <OaiListbox
                :minWidth="120"
                :options="[
                  { name: 'Date', value: 'date' },
                  { name: 'Updated', value: 'updated' },
                  { name: 'Comments', value: 'comments.created' },
                  { name: 'Customer', value: 'customer_name' },
                ]"
                v-model="sortBy"
              />

              <ArrowsUpDownIcon
                class="h-5 w-5 text-gray-400 cursor-pointer select-none"
                aria-hidden="true"
                @click="sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'"
              />
            </div>
          </div>

          <div class="ml-8 flex items-end">
            <button
              type="button"
              @click="clearFilters"
              class="rounded-md bg-gray-400 px-4 py-0 text-sm text-white hover:bg-gray-600 h-9 mb-1"
            >
              Reset
            </button>
          </div>
        </div>

        <div v-if="streamFilter.length" class="px-24 pb-4 flex gap-4 items-center">
          <span class="text-sm">Camera:</span>
          <div class="flex flex-wrap gap-3 items-center text-xs">
            <div
              v-for="item in streamFilter"
              :key="item._id"
              class="bg-gray-100 rounded-md px-2 py-1 font-medium text-gray-900 flex items-center"
            >
              <p class="mr-1">{{ item.customer_name }}/{{ item.site_id }} ({{ item.camera_id }})</p>

              <XMarkIcon
                class="h-4 w-4 text-red-500 cursor-pointer"
                @click="streamFilter = streamFilter.filter((stream) => stream._id !== item._id)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="px-20 py-5 grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] gap-4 justify-between"
      >
        <div
          class="p-4 w-64 relative rounded-md border border-transparent hover:border-gray-300 hover:shadow-md cursor-pointer"
          v-for="sequence in sequences.data"
          :key="sequence._id"
          @click="openSequence(sequence._id)"
        >
          <div class="h-40 mb-1 relative">
            <img
              :src="sequence.thumbnail_url"
              alt="thumbnail"
              class="w-full h-full object-cover"
              :key="sequence.thumbnail_url"
            />
            <div
              class="absolute top-0 left-0 h-full w-full bg-gray-900 bg-opacity-35 flex items-center"
              v-if="sequence.status === 'completed'"
            >
              <p class="py-1 text-white font-bold bg-green w-full text-center">Completed</p>
            </div>
            <div
              class="absolute top-0 left-0 h-full w-full bg-gray-900 bg-opacity-35 flex items-center"
              v-if="sequence.status === 'approved'"
            >
              <p class="py-1 text-white font-bold bg-purple-700 w-full text-center">Approved</p>
            </div>

            <div
              v-if="sequence.status === 'incomplete' && sequence.updated_by !== 'oai-automatic'"
              class="absolute w-5 h-5 rounded-full bg-yellow -top-2.5 -right-2.5"
            />

            <div
              v-if="sequence.comments.length"
              class="absolute w-5 h-5 rounded-full bg-red-500 text-white text-xs -top-2.5 -left-2.5 flex items-center justify-center"
            >
              {{ sequence.comments.length }}
            </div>
          </div>
          <p class="text-sm mb-2">
            <span
              >{{ sequence.customer_name }}/{{ sequence.site_id }}/{{ sequence.camera_id }}</span
            >
          </p>

          <div class="flex text-xs gap-2">
            <span class="text-gray-600 flex-1">Date:</span>
            <span class="text-gray-600 flex-1">{{ sequence.date }}</span>
          </div>
          <div class="flex text-xs gap-2">
            <span class="text-gray-600 flex-1">Frames:</span>
            <span class="text-gray-600 flex-1">
              {{ sequence.frames[0].frame_id }} -
              {{ sequence.frames[sequence.frames.length - 1].frame_id }}
            </span>
          </div>

          <div class="flex text-xs gap-2">
            <span class="text-gray-600 flex-1">Updated by:</span>
            <span class="text-gray-600 flex-1">{{ sequence.updated_by }}</span>
          </div>

          <div class="flex text-xs gap-2">
            <span class="text-gray-600 flex-1">Last updated:</span>
            <span class="text-gray-600 flex-1">{{
              format(sequence.updated, "dd.MM.yy HH:mm:ss")
            }}</span>
          </div>
        </div>
      </div>

      <button
        v-if="showLoadMoreButton"
        class="ml-24 flex text-xs items-center hover:text-white hover:bg-orange bg-gray-100 px-3 py-1 rounded-md leading-none border"
        @click="step += 1"
      >
        <ArrowDownIcon class="h-3 w-3" /> Load more
      </button>
    </div>
    <div class="w-64 h-screen border-l border-gray-300 overflow-hidden">
      <div class="h-16 flex items-center justify-between px-4 border-b border-gray-300">
        <span class="text-xl">Sequences</span>
        <button
          type="button"
          class="px-2 py-1 bg-green-500 text-white rounded"
          @click="createExport"
        >
          Export
        </button>
      </div>

      <div class="px-4 py-4 text-xs">
        <div class="flex">
          <span class="font-bold flex-[2]">Total:</span>
          <span class="flex-1">{{ sequences.total_count }} </span>
        </div>
        <div class="flex">
          <span class="font-bold flex-[2]">Completed:</span>
          <span class="flex-1">{{ sequences.completed_count }}</span>
        </div>

        <div class="flex">
          <span class="font-bold flex-[2]">Approved:</span>
          <span class="flex-1">{{ sequences.approved_count }}</span>
        </div>

        <div class="flex">
          <span class="font-bold flex-[2]">Not completed:</span>
          <span class="flex-1">{{
            sequences.total_count - sequences.completed_count - sequences.approved_count
          }}</span>
        </div>

        <div class="mt-4 flex">
          <span class="font-bold flex-[2]">Bboxes:</span>
          <span class="flex-1">{{ sequences.bbox_count }}</span>
        </div>
        <div class="flex">
          <span class="font-bold flex-[2]">Unique processes:</span>
          <span class="flex-1">{{ sequences.processes_count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon, ArrowsUpDownIcon, ArrowDownIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format } from "date-fns";
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import OaiCombobox from "shared/components/other/OaiCombobox.vue";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import { useCustomToast } from "shared/composables/toast";
import getProcessClasses from "shared/constants/ProcessClasses";
import logger from "shared/services/logger";
import { DecodedLabel, EncodedLabel } from "shared/types/ProcessClass";
import { Stream } from "shared/types/Stream";
import { useAllStreams } from "@/composables/stream";
import SequenceRepository from "@/repositories/SequenceRepository";
import { usePersonGadSequences } from "@/views/person_gad/composables";
import { SequenceQuery } from "@/views/person_gad/types";

const processClasses = getProcessClasses();
const { streams } = useAllStreams();
const toast = useCustomToast();
const router = useRouter();
const route = useRoute();

const isMounted = ref(false);
const streamFilter = ref<Stream[]>([]);
const processFilter = ref<DecodedLabel[]>([]);
const datePickerRange = ref<[Date, Date]>();
const statusFilter = ref<string | null>(null);
const sortBy = ref("date");
const sortOrder = ref("desc");
const step = ref(1);

onMounted(() => {
  if (route.query["stream_ids"]) {
    const streamIds = route.query["stream_ids"];
    streamFilter.value = streams.value.filter((stream) => streamIds.includes(stream._id));
  }

  if (route.query["process_classes"]) {
    const processClasses = route.query["process_classes"] as string[];
    processFilter.value = (processClasses.map(Number) as EncodedLabel[]).map(
      SequenceRepository.encodedLabelToDecodedLabel,
    ) as DecodedLabel[];
  }

  if (route.query["status"]) {
    statusFilter.value = route.query["status"] as string;
  }

  if (route.query["start_date"] && route.query["end_date"]) {
    datePickerRange.value = [
      new Date(route.query["start_date"] as string),
      new Date(route.query["end_date"] as string),
    ];
  }

  if (route.query["sort_by"]) {
    sortBy.value = route.query["sort_by"] as string;
  }

  if (route.query["sort_order"]) {
    sortOrder.value = route.query["sort_order"] as string;
  }

  if (route.query["step"]) {
    step.value = Number(route.query["step"]);
  }

  if (route.query["refresh"]) {
    refetch();
  }

  isMounted.value = true;
});

const sequencesFilter = computed(() => {
  if (!isMounted.value) {
    return {} as SequenceQuery;
  }

  const filter = {} as SequenceQuery;

  const streamQuery = streamFilter.value.map((stream) => stream._id);
  if (streamQuery.length) {
    filter["stream_ids"] = streamQuery;
  }

  const processQuery = processFilter.value.map((process) =>
    SequenceRepository.decodedLabelToEncodedLabel(process),
  );

  if (processQuery.length) {
    filter["process_classes"] = processQuery;
  }

  if (statusFilter.value !== null) {
    filter["status"] = statusFilter.value;
  }

  if (datePickerRange.value?.[0] && datePickerRange.value?.[1]) {
    filter["start_date"] = format(datePickerRange.value[0], "yyyy-MM-dd");
    filter["end_date"] = format(datePickerRange.value[1], "yyyy-MM-dd");
  }

  filter["step"] = step.value;
  filter["sort_by"] = sortBy.value;
  filter["sort_order"] = sortOrder.value;

  const query = SequenceRepository.objectToUrlSearchParams(filter).toString();
  router.replace(`${route.path}?${query}`);

  return filter;
});

const showLoadMoreButton = computed(() => {
  if (statusFilter.value === "completed") {
    return sequences.value.data.length < sequences.value.completed_count;
  } else if (statusFilter.value === "approved") {
    return sequences.value.data.length < sequences.value.approved_count;
  } else if (statusFilter.value === "incomplete") {
    return (
      sequences.value.data.length < sequences.value.total_count - sequences.value.completed_count
    );
  } else {
    return sequences.value.data.length < sequences.value.total_count;
  }
});

const { sequences, refetch } = usePersonGadSequences(sequencesFilter);

const createExport = async () => {
  try {
    await SequenceRepository.createSequencesDump();

    toast.success("Export created");
  } catch (_) {
    logger.error("Export creation failed");
    toast.error("Export creation failed");
  }
};

const openSequence = (sequenceId: string) => {
  const query = SequenceRepository.objectToUrlSearchParams(route.query).toString();

  router.push(`/person-gad/${sequenceId}?${query}`);
};

const clearFilters = () => {
  streamFilter.value = [];
  processFilter.value = [];
  datePickerRange.value = undefined;
  statusFilter.value = null;
  sortBy.value = "date";
  sortOrder.value = "desc";
  step.value = 1;
};
</script>
