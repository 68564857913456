<template>
  <div class="relative w-full h-14 flex items-center justify-center border-t border-gray-300">
    <div class="absolute left-10 flex gap-2 text-md font-mono" v-if="groupService">
      <span class="text-gray-800">
        {{ groupService.frameIndex + 1 }}
      </span>

      <span class="text-gray-400">/</span>
      <span class="text-gray-400">15</span>
    </div>

    <div class="flex items-center gap-2">
      <ChevronDoubleLeftIcon
        class="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700"
        @click="setFirstFrame"
      />
      <ChevronLeftIcon
        class="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700"
        @click="setPreviousFrame"
      />
      <PlayIcon
        class="w-5 h-5 mx-1 cursor-pointer text-gray-500 hover:text-gray-700"
        @click="handlePlay"
        v-if="!playInterval"
      />
      <PauseIcon
        class="w-5 h-5 mx-1 cursor-pointer text-gray-500 hover:text-gray-700"
        @click="handlePlay"
        v-else
      />
      <ChevronRightIcon
        class="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700"
        @click="setNextFrame"
      />

      <ChevronDoubleRightIcon
        class="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700"
        @click="setLastFrame"
      />
    </div>

    <div class="absolute right-10 flex gap-2" v-if="groupService">
      <RouterLink
        :to="prevSequenceUrl"
        :class="prevSequenceUrl ? 'text-gray-500 hover:text-gray-700' : 'text-gray-300'"
        :disabled="!prevSequenceUrl"
      >
        <ArrowLeftIcon class="w-4 h-4" />
      </RouterLink>

      <RouterLink
        :to="nextSequenceUrl"
        :class="nextSequenceUrl ? 'text-gray-500 hover:text-gray-700' : 'text-gray-300'"
        :disabled="!nextSequenceUrl"
      >
        <ArrowRightIcon class="w-4 h-4" />
      </RouterLink>
    </div>

    <div
      v-if="groupService"
      class="absolute -top-1 left-0 w-full h-1 bg-yellow transition-[width] ease-in-out duration-500"
      :style="`width: ${((groupService.frameIndex + 1) * 100) / groupService.MAX_FRAMES_NUMBER}%`"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/vue/24/outline";
import { PlayIcon, PauseIcon } from "@heroicons/vue/24/solid";
import { PropType, ref, computed } from "vue";
import { useRoute } from "vue-router";
import SequenceRepository from "@/repositories/SequenceRepository";
import { usePersonGadSequences } from "@/views/person_gad/composables";
import { SequenceQuery } from "@/views/person_gad/types";
import GroupService from "../services/groupService";

const props = defineProps({
  groupService: {
    type: Object as PropType<GroupService>,
  },
  query: {
    type: Object as PropType<Record<string, unknown>>,
  },
});

const route = useRoute();

const playInterval = ref(0);

const query = computed(() => {
  if (!props.query) {
    return {} as SequenceQuery;
  }

  const objectQuery = { ...props.query } as SequenceQuery;
  if (objectQuery.process_classes) {
    objectQuery.process_classes = objectQuery.process_classes.map(Number);
  }
  if (objectQuery.limit) {
    objectQuery.limit = Number(objectQuery.limit);
  }
  if (objectQuery.step) {
    objectQuery.step = Number(objectQuery.step);
  }

  return objectQuery;
});

const { sequences } = usePersonGadSequences(query);

const prevSequenceUrl = computed(() => {
  const sequenceId = route.params.id as string;
  const curSequenceIndex = sequences.value.data.findIndex(
    (sequence) => sequence._id === sequenceId,
  );

  if (curSequenceIndex === 0) {
    return "";
  }

  const prevSequenceIndex = curSequenceIndex < 0 ? 0 : curSequenceIndex - 1;
  const searchParams = SequenceRepository.objectToUrlSearchParams(query.value).toString();
  return `/person-gad/${sequences.value.data[prevSequenceIndex]?._id}?${searchParams}`;
});

const nextSequenceUrl = computed(() => {
  const sequenceId = route.params.id as string;
  const curSequenceIndex = sequences.value.data.findIndex(
    (sequence) => sequence._id === sequenceId,
  );

  if (curSequenceIndex === sequences.value.data.length - 1) {
    return "";
  }

  const nextSequenceIndex = curSequenceIndex < 0 ? 0 : curSequenceIndex + 1;
  const searchParams = SequenceRepository.objectToUrlSearchParams(query.value).toString();
  return `/person-gad/${sequences.value.data[nextSequenceIndex]?._id}?${searchParams}`;
});

const setNextFrame = () => {
  if (!props.groupService) {
    return;
  }

  const nextFrameIndex = (props.groupService.frameIndex + 1) % props.groupService.MAX_FRAMES_NUMBER;

  props.groupService.setFrameIndex(nextFrameIndex);
};

const setPreviousFrame = () => {
  if (!props.groupService) {
    return;
  }

  const previousFrameIndex =
    (props.groupService.frameIndex - 1 + props.groupService.MAX_FRAMES_NUMBER) %
    props.groupService.MAX_FRAMES_NUMBER;

  props.groupService.setFrameIndex(previousFrameIndex);
};

const setFirstFrame = () => {
  if (!props.groupService) {
    return;
  }

  props.groupService.setFrameIndex(0);
};

const setLastFrame = () => {
  if (!props.groupService) {
    return;
  }

  props.groupService.setFrameIndex(props.groupService.MAX_FRAMES_NUMBER - 1);
};

const handlePlay = () => {
  if (playInterval.value) {
    clearInterval(playInterval.value);
    playInterval.value = 0;
    return;
  }

  playInterval.value = setInterval(() => {
    setNextFrame();
  }, 800) as unknown as number;
};
</script>
