<template>
  <div v-if="loading" class="flex items-center justify-center" style="width: 300px; height: 200px">
    <LoadingSpinner />
  </div>
  <div v-else style="max-height: calc(100vh - 100px)" class="overflow-auto text-left">
    <router-link
      :to="{ name: 'PlanHierarchy', params: { customer_name: customerName, site_id: siteId } }"
      class="inline-flex items-center gap-1"
      ><ArrowTopRightOnSquareIcon class="w-5 h-5" /> Hierarchy</router-link
    >
    <table>
      <thead class="bg-gray-100">
        <tr>
          <th />
          <th
            v-for="level in levels"
            :key="level._id"
            style="writing-mode: vertical-lr; transform: rotateZ(180deg)"
            class="text-left"
            :style="
              level._id === currentLevelId
                ? 'border-top: 2px solid #000; border-left: 2px solid #000; border-right: 2px solid #000;'
                : ''
            "
          >
            <span
              :class="{
                'font-bold': level._id === currentLevelId,
              }"
            >
              {{ level.name }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="section in sectionsWithLevels" :key="section._id">
          <td class="bg-gray-100 text-right">{{ section.name }}</td>
          <td
            v-for="level in section.levels"
            :key="level._id"
            :class="{
              'bg-gray-50': level.state === 'not-needed',
              'bg-yellow-100': level.state === 'no-mask',
              'bg-green-200': level.state === 'has-mask',
            }"
            :style="
              level._id === currentLevelId
                ? `border-left: 2px solid #000; border-right: 2px solid #000; ${
                    section === sectionsWithLevels[sectionsWithLevels.length - 1]
                      ? 'border-bottom: 2px solid #000;'
                      : ''
                  }`
                : ''
            "
          >
            <div v-if="level.state === 'not-needed'" class="text-gray-500 text-center">-</div>
            <div v-if="level.state === 'has-mask'" class="tooltip">
              <CheckIcon class="w-4 h-4 text-white ml-0.5" />
              <div class="tooltiptext tooltip-right text-xs text-left">
                {{ level.sectionMask.created_by }} on
                {{
                  level.sectionMask.created &&
                  format(parseISO(level.sectionMask.created), "dd.MM.yyyy HH:mm")
                }}
              </div>
            </div>
            <XMarkIcon class="w-4 h-4 text-gray-500 ml-1" v-if="level.state === 'no-mask'" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { format, parseISO } from "date-fns";
import { computed, onMounted, PropType, ref } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import HierarchyRepository from "shared/repositories/HierarchyRepository";
import SectionMasksRepository from "shared/repositories/SectionMasksRepository";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { SectionMask, SectionMaskPossibility } from "shared/types/SectionMask";

const loading = ref(false);
const hierarchyTags = ref<HierarchyTagStore[]>([]);
const sectionMasks = ref<SectionMask[]>([]);
const sectionMaskPossibilities = ref<SectionMaskPossibility[]>([]);

const props = defineProps({
  customerName: {
    type: String,
    required: true,
  },
  siteId: {
    type: String,
    required: true,
  },
  buildingId: {
    type: String as PropType<string | null>,
    required: true,
  },
  currentLevelId: {
    type: String,
    required: true,
  },
});

const levels = computed(() =>
  hierarchyTags.value.filter((tag) => tag.type === "level").sort((a, b) => a.number - b.number),
);
const sections = computed(() =>
  hierarchyTags.value.filter((tag) => tag.type === "section").sort((a, b) => a.number - b.number),
);
const sectionMasksByIds = computed(() =>
  sectionMasks.value.reduce((acc, item) => {
    const key = [item.building_id, item.level_id, item.section_id].join("_");
    acc[key] = item;
    return acc;
  }, {} as Record<string, SectionMask>),
);
const sectionMaskPossibilityMap = computed(() =>
  sectionMaskPossibilities.value.reduce((acc, item) => {
    for (const possibility of item.possibilities) {
      const key = [possibility.building_id, possibility.level_id, possibility.section_id].join("_");
      acc.add(key);
    }
    return acc;
  }, new Set<string>()),
);

onMounted(() => {
  loading.value = true;
  Promise.all([
    HierarchyRepository.loadHierarchyTags(props.customerName, props.siteId).then((tags) => {
      hierarchyTags.value = tags;
    }),
    SectionMasksRepository.loadSectionMasks(props.customerName, props.siteId).then((items) => {
      sectionMasks.value = items;
    }),
    SectionMasksRepository.loadSectionMaskPossibilityMap(props.customerName, props.siteId).then(
      (map) => {
        sectionMaskPossibilities.value = map;
      },
    ),
  ]).finally(() => {
    loading.value = false;
  });
});

const findSectionMask = (level: HierarchyTagStore, section: HierarchyTagStore) => {
  const key = [props.buildingId, level._id, section._id].join("_");
  return sectionMasksByIds.value[key];
};

const getState = (
  level: HierarchyTagStore,
  section: HierarchyTagStore,
  sectionMask: SectionMask,
) => {
  if (sectionMask) {
    return "has-mask";
  }
  const key = [props.buildingId, level._id, section._id].join("_");
  return sectionMaskPossibilityMap.value.has(key) ? "no-mask" : "not-needed";
};

const sectionsWithLevels = computed(() =>
  sections.value.map((section) => ({
    ...section,
    levels: levels.value.map((level) => {
      const sectionMask = findSectionMask(level, section);
      return {
        ...level,
        sectionMask,
        state: getState(level, section, sectionMask),
      };
    }),
  })),
);
</script>

<style scoped>
th,
td {
  @apply border;
  @apply p-2;
  @apply font-normal;
}
</style>
