<template>
  <MainLayout :activeItem="'Planner'">
    <LoadingSection :loading="!dataLoaded" />
    <div class="flex flex-col flex-1" v-if="dataLoaded">
      <header
        class="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6 sticky top-0 z-10 bg-white"
      >
        <div class="text-lg font-semibold text-gray-900">
          {{ planConfig?.plan.name
          }}<span class="text-xs text-red-500 pl-4" v-if="validationError">{{ errorMessage }}</span>
        </div>

        <div class="flex min-h-9 items-center flex-row gap-2">
          <button
            type="button"
            class="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm bg-yellow-400 hover:bg-yellow-600"
            @click="sectionMasks = true"
          >
            <span>Masks</span>
          </button>
          <button
            type="button"
            class="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm bg-green-400 hover:bg-green-600"
            @click="saveData"
            v-if="hasChanges"
            :disabled="saveLoading"
          >
            <span class="pr-2">
              <CheckIcon v-if="!saveLoading" class="h-5 w-5" aria-hidden="true" />
              <LoadingSpinner :cls="'text-white'" v-else size="h-5 w-5" />
            </span>
            <span>Save</span>
          </button>
          <Menu as="div" class="pr-2">
            <MenuButton
              type="button"
              class="inline-flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
            >
              Hierarchy
              <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <MenuItems
              class="absolute right-50 z-50 mt-3 w-36 origin-top-right rounded-md bg-white shadow-lg focus:outline-none"
            >
              <MenuItem
                v-slot="{ active }"
                v-for="mode in modes"
                :key="mode"
                @click="changeMode(mode)"
                class="capitalize"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  >{{ mode }}</a
                >
              </MenuItem>
            </MenuItems>
          </Menu>
          <Menu as="div" class="inline-block text-left leading-none">
            <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
              <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-5 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 divide-y">
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="plannerRemapping = true"
                    >
                      <AdjustmentsHorizontalIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Planner Remapping</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="sectionMaskRemapping = true"
                    >
                      <CubeIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Section Mask Remapping</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="reinitializePlannerActuals = true"
                    >
                      <ArrowPathIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Reinitialize Planner Actuals</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="isSectioningPDFsModalOpen = true"
                    >
                      <DocumentIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Sectioning PDFs</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="isCriticalPathModalOpen = true"
                    >
                      <ArrowPathRoundedSquareIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Critical Path</span>
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </header>
      <div class="flex" v-if="plannerItemsTree.length > 0">
        <div class="flex-1 px-2 overflow-y-scroll pt-2 pb-6" style="max-height: calc(100vh - 80px)">
          <div v-for="(value, index) in plannerItemsTree" :key="index" class="">
            <HierarchyPlanItem
              v-if="mappingTable"
              :item="value"
              :mappingTable="mappingTable"
              :sectionMaskPossibilityMap="sectionMaskPossibilityMap"
              :sectionMaskSet="sectionMaskSet"
              :copiedTag="copiedHierarchyTag"
              :isInherited="isInherited"
              :getTagColor="getTagColor"
              :formatTagText="formatTagText"
              :validateTagMove="validateTagMove"
              :resetValidationError="resetValidationError"
              :depth="0"
              @removeTagInHierarchy="removeTagInHierarchy"
              @postProcessTagMove="postProcessTagMove"
              @toggleTrackingEnabled="toggleTrackingEnabled"
            ></HierarchyPlanItem>
          </div>
        </div>
        <div
          class="flex-1 overflow-y-scroll pt-2 pb-6"
          style="max-height: calc(100vh - 80px); padding-left: 20px"
        >
          <ul role="list" class="divide-y divide-gray-100">
            <li
              v-for="group in partitionedTags"
              :key="group.type"
              class="flex items-start gap-x-6 py-3"
            >
              <div class="min-w-0">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ capitalizeSectionType(group.type) }}
                </p>
                <div class="flex flex-wrap gap-x-2 gap-y-2 mt-1 leading-5">
                  <draggable
                    class="list-group flex flex-wrap gap-1"
                    :list="group.tags"
                    :group="{ name: 'hierarchy', pull: 'clone', put: false }"
                    itemKey="name"
                    ghost-class="ghost"
                    :move="validateTagMove"
                    @end="handleDragEnd(group)"
                  >
                    <template #item="{ element }">
                      <div
                        @dblclick="
                          openEditHierarchyTagModal = true;
                          currentTag = element;
                          processValidationErrors = [];
                        "
                        :class="[
                          `inline-flex items-center justify-end rounded-md pl-2 py-0.5 text-xs font-medium list-group-item border-2 px-2`,
                          `bg-${getTagColor(element.type)}-100`,
                          `text-${getTagColor(element.type)}-700`,
                          focusedHierarchyTagId !== element._id &&
                            copiedHierarchyTag?._id !== element._id &&
                            'border-transparent',
                          focusedHierarchyTagId === element._id && getTagBorderStyle(element.type),
                          copiedHierarchyTag?._id === element._id &&
                            `${getTagBorderStyle(element.type)} border-dashed`,
                        ]"
                        :data-tag-id="element._id"
                      >
                        {{ formatTagText(element) }}
                        {{
                          element.type === "component"
                            ? ` → ${element.attached_processes.included.length}/${
                                element.attached_processes.included.length +
                                element.attached_processes.excluded.length
                              }`
                            : ""
                        }}
                        {{ element.splits ? ` → ` : "" }}
                        <div
                          v-if="element.splits"
                          class="bg-white py-0.5 px-2 rounded-md font-semibold text-gray"
                        >
                          {{ `${element.splits.length} Splits` }}
                        </div>

                        <Menu as="div" class="text-left relative pl-2">
                          <div class="pt-1 relative">
                            <MenuButton class="group relative h-3.5 w-3.5 rounded-sm">
                              <EllipsisVerticalIcon class="h-3.5 w-3.5" aria-hidden="true" />
                              <span class="absolute -inset-1" />
                            </MenuButton>
                          </div>

                          <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                          >
                            <MenuItems
                              class="absolute right-0 z-50 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-700 ring-opacity-5"
                            >
                              <div class="py-1">
                                <MenuItem
                                  v-slot="{ active }"
                                  @click="
                                    openEditHierarchyTagModal = true;
                                    currentTag = element;
                                    processValidationErrors = [];
                                  "
                                >
                                  <a
                                    href="#"
                                    :class="[
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'group flex items-center px-3 py-2',
                                    ]"
                                  >
                                    <PencilIcon
                                      class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Edit Tag</a
                                  >
                                </MenuItem>
                                <MenuItem
                                  v-slot="{ active }"
                                  @click="deleteTag(element._id, element.type)"
                                >
                                  <a
                                    href="#"
                                    :class="[
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'group flex items-center px-3 py-2',
                                    ]"
                                  >
                                    <TrashIcon
                                      class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Delete Tag</a
                                  >
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </transition>
                        </Menu>
                      </div>
                    </template>
                  </draggable>
                </div>
                <div class="pt-3">
                  <button class="hover:bg-gray-100 rounded-md" @click="addHierarchyTag(group.type)">
                    <PlusIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <NoDataYet desc="Please upload a plan to set up the hierarchy!" />
      </div>
      <Modal
        :open="openEditHierarchyTagModal"
        @close="closeModal()"
        customCls="w-full m-5 md:w-3/4"
      >
        <template #title>Edit Tag</template>
        <template #content>
          <div class="grid grid-cols-1 gap-6">
            <div class="col-span-1 text-left">
              <div class="text-gray-700 text-sm font-semibold">Tag Name</div>
              <input
                v-model="currentTag.name"
                type="text"
                name="tag-name"
                class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-500"
                placeholder="Tag name"
                ref="tagNameInput"
              />
            </div>
            <div class="col-span-1 text-left">
              <div class="text-gray-700 text-sm font-semibold">Tag Number</div>
              <input
                v-model="currentTag.number"
                type="number"
                name="tag-number"
                class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-500"
                placeholder="Tag number"
              />
            </div>
          </div>
          <div v-if="currentTag.type === 'component' && currentTag.attached_processes" class="pt-4">
            <div class="flex text-gray-700 items-center justify-center">
              <div class="font-semibold">Process Mapping</div>
            </div>
            <div
              class="flex justify-center items-center flex-row gap-2 py-2"
              v-if="project.process_groups.includes('default')"
            >
              <div v-for="preset in componentPresets" :key="preset.name">
                <button
                  class="inline-flex items-center rounded-md px-3 py-2 text-xs text-white shadow-sm bg-gray-400 hover:bg-gray-600"
                  @click="selectComponentPreset(preset)"
                >
                  {{ preset.name }}
                </button>
              </div>
            </div>
            <div
              class="text-xs text-red-500 text-left mb-2"
              v-if="processValidationErrors.length > 0"
            >
              {{ processValidationErrors[0].error }}
              (Source ids:
              {{ processValidationErrors.map((item) => item.plannerItem.source_id).join(", ") }})
            </div>
            <div class="flex mt-2" style="min-height: 300px">
              <div class="w-1/2 bg-gray-200 p-2">
                <draggable
                  class="list-group w-full h-full"
                  :list="currentTag.attached_processes.included"
                  group="processes"
                  itemKey="included"
                  @end="sortProcessList()"
                  :move="moveIncludedProcessItem"
                >
                  <template #item="{ element }">
                    <div
                      :class="[
                        `m-0.5 inline-flex items-center gap-x-1.5 rounded-md bg-${getBuildingElementColor(
                          element.buildingElement,
                        )}-100 px-2 py-1 text-xs font-medium text-${getBuildingElementColor(
                          element.buildingElement,
                        )}-700 list-group-item`,
                      ]"
                    >
                      {{ element.decodedLabel }}
                    </div>
                  </template>
                </draggable>
              </div>
              <div class="w-1/2 bg-gray-300 p-2">
                <draggable
                  class="list-group w-full h-full"
                  :list="currentTag.attached_processes.excluded"
                  group="processes"
                  itemKey="excluded"
                  @end="sortProcessList()"
                  :move="validateProcessMove"
                >
                  <template #item="{ element }">
                    <div
                      :class="[
                        `m-0.5 inline-flex items-center gap-x-1.5 rounded-md bg-${getBuildingElementColor(
                          element.buildingElement,
                        )}-100 px-2 py-1 text-xs font-medium text-${getBuildingElementColor(
                          element.buildingElement,
                        )}-700 list-group-item`,
                      ]"
                    >
                      {{ element.decodedLabel }}
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>

          <div class="mt-4" v-if="currentTag.type === 'level'">
            <button
              type="button"
              class="mb-2 leading-normal px-3 py-1 rounded-md text-white bg-yellow-400 hover:bg-yellow-600"
              @click="addLevelTagSplit"
            >
              {{ currentTag.splits ? "Add Tag split" : "Create Tag split" }}
            </button>

            <LevelSplitEditor
              v-if="currentTag.splits"
              :splits="currentTag.splits"
              @removeSplit="removeLevelTagSplit"
              @updateSplits="currentTag.splits = $event"
            />
          </div>
        </template>
      </Modal>
    </div>

    <SectionMasks
      v-if="sectionMasks"
      :open="sectionMasks"
      :streams="streams"
      :combinations="sectionMaskCombinations.combinations"
      :tagMap="tagMap"
      :masks="maskList"
      :formatTagText="formatTagText"
      :sectionMaskDateRange="sectionMaskDateRange"
      @closeModal="sectionMasks = false"
      @updateMasks="updateSectionMasks"
      @focusMask="focusValidityRangeMask"
    ></SectionMasks>

    <PlannerProcessMapping
      :open="plannerRemapping"
      :processes="processes"
      :remapLoading="remapLoading"
      @closeModal="plannerRemapping = false"
      @remapAllProcesses="applyProcessRemapping"
    >
    </PlannerProcessMapping>
    <SectioningPdfsModal
      v-if="isSectioningPDFsModalOpen"
      @close="isSectioningPDFsModalOpen = false"
    />
    <SectionMaskMapping
      :open="sectionMaskRemapping"
      :cameraIds="cameraIds"
      @closeModal="sectionMaskRemapping = false"
    >
    </SectionMaskMapping>
    <ReinitializePlannerActuals
      :open="reinitializePlannerActuals"
      @closeModal="reinitializePlannerActuals = false"
    >
    </ReinitializePlannerActuals>
    <Modal :open="orphanMasksModal" @close="orphanMasksModal = false" customCls="w-1/3">
      <template #title>Orphan Masks</template>
      <template #content>
        <div class="overflow-hidden rounded-md border border-gray-300 bg-white">
          <ul role="list" class="divide-y divide-gray-300">
            <li
              v-for="mask in orphanMasks"
              :key="mask._id"
              class="px-6 py-2 text-sm text-gray-800 font-semibold"
            >
              {{
                `${formatMaskName(mask)}: ${
                  mask.validity_start_local
                    ? formatTimestampDisplay(mask.validity_start_local)
                    : "today"
                } - ${
                  mask.validity_end_local
                    ? formatTimestampDisplay(mask.validity_end_local)
                    : "today"
                }`
              }}
            </li>
          </ul>
        </div>
        <div class="flex justify-center pt-4">
          <button
            type="button"
            class="flex-row mr-2 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm bg-gray-400 hover:bg-gray-600"
            @click="orphanMasksModal = false"
          >
            <slot name="confirmAction">Go Back</slot>
          </button>
          <button
            type="button"
            class="flex-row pr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm bg-red-400 hover:bg-red-600"
            @click="saveAndDeprecateMasks(), (orphanMasksModal = false)"
          >
            <slot name="cancelAction">Save & Deprecate</slot>
          </button>
        </div>
      </template>
    </Modal>
    <CriticalPathModal v-if="isCriticalPathModalOpen" @close="isCriticalPathModalOpen = false" />
  </MainLayout>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  AdjustmentsHorizontalIcon,
  CheckIcon,
  ChevronDownIcon,
  CubeIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  DocumentIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/vue/24/outline";
import { useQueryClient } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "vue";
import { NavigationGuardNext, RouteLocationNormalized, useRouter, useRoute } from "vue-router";
import draggable from "vuedraggable";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCustomToast, useSaveBeforeLeaveConfirmationModal } from "shared/composables/toast";
import HierarchyRepository from "shared/repositories/HierarchyRepository";
import PlannerRepository from "shared/repositories/PlannerRepository";
import SectionMasksRepository from "shared/repositories/SectionMasksRepository";
import logger from "shared/services/logger";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import { PlanConfig, PlannerItem, PlannerItemWithChildren, PlannerMode } from "shared/types/Plan";
import { BuildingElement, ProcessClass } from "shared/types/ProcessClass";
import { SectionMask } from "shared/types/SectionMask";
import CriticalPathModal from "shared/views/critical_path/components/CriticalPathModal.vue";
import MainLayout from "@/components/layout/MainLayout.vue";
import NoDataYet from "@/components/other/NoDataYet.vue";
import { useProcessClasses } from "@/composables/process";
import { useCurrentProject } from "@/composables/project";
import StreamRepository from "@/repositories/StreamRepository";
import ValidationRepository from "@/repositories/ValidationRepository";
import {
  AttachedProcesses,
  HierarchyTag,
  PartitionedTags,
  TagCombination,
} from "@/types/HierarchyTag";
import { ComponentPreset } from "@/types/Process";
import { Stream } from "@/types/Stream";
import { MappingProcess } from "@/types/Validation";
import { ChangeEvent, MoveEvent } from "@/types/Vuedraggable";
import HierarchyPlanItem from "@/views/plan_hierarchy/component/HierarchyPlanItem.vue";
import LevelSplitEditor from "@/views/plan_hierarchy/component/LevelSplitEditor.vue";
import PlannerProcessMapping from "@/views/plan_hierarchy/component/PlannerProcessMapping.vue";
import ReinitializePlannerActuals from "@/views/plan_hierarchy/component/ReinitializePlannerActuals.vue";
import SectionMaskMapping from "@/views/plan_hierarchy/component/SectionMaskMapping.vue";
import SectionMasks from "@/views/plan_hierarchy/component/SectionMasks.vue";
import SectioningPdfsModal from "@/views/plan_hierarchy/component/SectioningPdfsModal.vue";

export default defineComponent({
  name: "PlanHierarchy",
  components: {
    ReinitializePlannerActuals,
    CriticalPathModal,
    SectioningPdfsModal,
    PlusIcon,
    EllipsisVerticalIcon,
    CubeIcon,
    AdjustmentsHorizontalIcon,
    TrashIcon,
    PencilIcon,
    ChevronDownIcon,
    CheckIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Modal,
    draggable,
    HierarchyPlanItem,
    MainLayout,
    SectionMasks,
    LoadingSpinner,
    LoadingSection,
    PlannerProcessMapping,
    SectionMaskMapping,
    NoDataYet,
    DocumentIcon,
    ArrowPathIcon,
    LevelSplitEditor,
    ArrowPathRoundedSquareIcon,
  },
  data: () => ({
    hierarchyTags: [] as HierarchyTag[],
    partitionedTags: [] as PartitionedTags[],
    mappingTable: {} as Record<string, HierarchyTag[]>,
    openEditHierarchyTagModal: false as boolean,
    currentTag: {} as HierarchyTag,
    planConfig: null as PlanConfig | null,
    hasChanges: false as boolean,
    dataLoaded: false as boolean,
    validationError: false as boolean,
    errorMessage: "" as string,
    sectionMasks: false as boolean,
    plannerRemapping: false as boolean,
    sectionMaskRemapping: false as boolean,
    reinitializePlannerActuals: false as boolean,
    streams: [] as Stream[],
    maskList: [] as SectionMask[],
    orphanMasksModal: false as boolean,
    orphanMasks: [] as SectionMask[],
    saveLoading: false as boolean,
    plannerItemsChanges: new Set<string>() as Set<string>,
    processes: [] as MappingProcess[],
    remapLoading: false as boolean,
    processValidationErrors: [] as { plannerItem: PlannerItem; error: string | null }[],
    sectionMaskDateRange: {} as Record<string, { start: Date; end: Date }>,
    isSectioningPDFsModalOpen: false as boolean,
    isCriticalPathModalOpen: false as boolean,
    focusedHierarchyTagId: undefined as string | undefined,
    copiedHierarchyTag: undefined as HierarchyTag | undefined,
  }),
  watch: {
    mappingTable: {
      deep: true,
      handler(newValue) {
        if (Object.keys(newValue).length !== 0 && this.dataLoaded) {
          this.hasChanges = true;
        }
      },
    },
    partitionedTags: {
      deep: true,
      handler(newValue) {
        if (Object.keys(newValue).length !== 0 && this.dataLoaded) {
          this.hasChanges = true;
        }
      },
    },
    maskList: {
      deep: true,
      handler(newValue) {
        if (Object.keys(newValue).length !== 0 && this.dataLoaded) {
          this.hasChanges = true;
        }
      },
    },
    currentTag(newValue) {
      if (newValue.type === "component") {
        this.sortProcessList();
      }
    },
  },
  beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (!this.hasChanges) {
      next();
    } else {
      this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
        if (confirmed) {
          this.hasChanges = false;
          this.router.push(to.fullPath);
        }
      });
    }
  },
  computed: {
    modes() {
      return [
        "visitor",
        this.hasPermission(["pct_tracking_admin_plan", "pct_tracking_plan"]) && "tracker",
        this.hasPermission(["pct_tracking_admin_plan", "pct_tracking_plan"]) && "revision",
        this.hasPermission(["pct_tracking_admin_plan", "pct_tracking_plan"]) && "hierarchy",
      ].filter((mode) => mode) as PlannerMode[];
    },
    editableHierarchyTypes(): HierarchyType[] {
      return ["building", "level", "section", "component"];
    },
    buildingElements(): BuildingElement[] {
      return ["ground", "foundation", "ceiling", "wall", "roof"];
    },
    componentPresets() {
      return [
        {
          name: "Ground",
          type: "range",
          classes: [0, 2],
        },
        {
          name: "Foundation",
          type: "range",
          classes: [3, 22],
        },
        {
          name: "Ceiling",
          type: "single",
          classes: [
            23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 65, 66, 67, 68, 69, 70,
          ],
        },
        {
          name: "Underbeams",
          type: "single",
          classes: [30, 33, 36, 37],
        },
        {
          name: "Walls",
          type: "single",
          classes: [
            39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 60, 61, 62, 63, 64,
          ],
        },
        {
          name: "Brickwork",
          type: "single",
          classes: [39, 40, 63],
        },
        {
          name: "Walls (insitu)",
          type: "single",
          classes: [44, 47, 50, 51],
        },
        {
          name: "Columns (insitu)",
          type: "single",
          classes: [45, 48, 52, 53],
        },
        {
          name: "Parapet",
          type: "single",
          classes: [46, 49, 54, 55, 63, 64],
        },
        {
          name: "Roof",
          type: "range",
          classes: [56, 59],
        },
      ] as ComponentPreset[];
    },
    colorConfigTag(): Record<HierarchyType, string> {
      return {
        building: "green",
        level: "blue",
        section: "red",
        component: "purple",
      };
    },
    borderColorConfigTag(): Record<HierarchyType, string> {
      return {
        building: "border-green-600",
        level: "border-blue-600",
        section: "border-red-600",
        component: "border-purple-600",
      };
    },
    colorConfigBuildingElement(): Record<BuildingElement, string> {
      return {
        ground: "orange",
        foundation: "gray",
        ceiling: "green",
        wall: "blue",
        roof: "red",
      };
    },
    plannerItemsTree() {
      if (!this.planConfig || !this.planConfig.planner_items.length) {
        return [];
      }

      const dictionary = {} as Record<string, PlannerItemWithChildren>;
      const roots = [] as PlannerItemWithChildren[];

      this.planConfig.planner_items.forEach((item) => {
        dictionary[item._id] = { ...item } as PlannerItemWithChildren;
      });

      this.planConfig.planner_items.forEach((item) => {
        if (item.parent_id && dictionary[item.parent_id]) {
          if (!dictionary[item.parent_id].children) {
            dictionary[item.parent_id].children = [];
          }
          dictionary[item.parent_id].children?.push(dictionary[item._id]);
        } else {
          roots.push(dictionary[item._id]);
        }
      });

      return roots;
    },
    plannerItemsIndexMap() {
      return this.planConfig?.planner_items.reduce((map, obj, index) => {
        map[obj.source_id] = index;
        return map;
      }, {} as Record<string, number>);
    },
    plannerItemsIdMap() {
      return this.planConfig?.planner_items.reduce((dict, item) => {
        dict[item._id] = item;
        return dict;
      }, {} as Record<string, PlannerItem>);
    },
    sectionMaskCombinations() {
      const combinationSet = new Set<string>();
      const mappingTable = this.mappingTable;
      const getSectionMaskCombination = this.getSectionMaskCombinations.bind(this);
      const isMappingLeafNode = this.isMappingLeafNode.bind(this);

      function traverse(node: PlannerItemWithChildren) {
        if (isMappingLeafNode(node)) {
          const leafTags = mappingTable[node.source_id];
          const combinationKeys = getSectionMaskCombination(leafTags);

          if (combinationKeys) {
            combinationKeys.forEach((key) => combinationSet.add(key));
          }
          return;
        }
        node.children?.forEach(traverse);
      }

      if (this.plannerItemsTree && this.mappingTable) {
        for (const subTree of this.plannerItemsTree) {
          traverse(subTree);
        }
      }
      const combinations: TagCombination[] = [];

      Array.from(combinationSet).forEach((comb) => {
        combinations.push(JSON.parse(comb));
      });

      return { combinations, combinationSet };
    },
    sectionMaskSet() {
      const maskSet = new Set<string>();
      this.maskList.forEach((mask) => {
        maskSet.add(
          JSON.stringify({
            building_id: mask.building_id,
            level_id: mask.level_id,
            section_id: mask.section_id,
            level_split_id: mask.level_split_id,
          }),
        );
      });
      return maskSet;
    },
    sectionMaskPossibilityMap(): Record<string, Set<string>> {
      const possibilityMap: Record<string, Set<string>> = {};
      const mappingTable = this.mappingTable;
      const getSectionMaskCombinations = this.getSectionMaskCombinations.bind(this);
      const isMappingLeafNode = this.isMappingLeafNode.bind(this);

      function traverse(node: PlannerItemWithChildren, parentSourceIds: string[]) {
        if (isMappingLeafNode(node)) {
          const possibilities = getSectionMaskCombinations(mappingTable[node.source_id]);
          if (!possibilities) {
            return;
          }

          parentSourceIds.forEach((sourceId) => {
            if (sourceId in possibilityMap) {
              possibilities.forEach((possibility) => possibilityMap[sourceId].add(possibility));
            } else {
              possibilityMap[sourceId] = new Set(possibilities);
            }
          });

          return;
        }
        node.children?.forEach((child) => {
          parentSourceIds.push(child.source_id);
          traverse(child, parentSourceIds);
          parentSourceIds.pop();
        });
      }

      if (this.plannerItemsTree && mappingTable) {
        for (const subTree of this.plannerItemsTree) {
          traverse(subTree, [subTree.source_id]);
        }
      }
      return possibilityMap;
    },
    tagMap() {
      const hierarchyTags = {} as Record<string, HierarchyTag>;
      for (const partitionedTag of this.partitionedTags) {
        for (const tag of partitionedTag.tags) {
          hierarchyTags[tag._id] = tag;

          if (tag.splits) {
            tag.splits.forEach((split) => {
              if (!split.id) {
                return;
              }

              hierarchyTags[split.id] = {
                ...tag,
                ...split,
                name: `${tag.name} - ${split.name}`,
                _id: split.id,
                splits: null,
              };
            });
          }
        }
      }
      return hierarchyTags;
    },
    tagMappingTable() {
      const tagMappingTable: Record<string, HierarchyTag> = this.mapPartitionedHierarchyGroups();
      for (const [key, value] of Object.entries(this.mappingTable)) {
        value.forEach((tag) => {
          tagMappingTable[tag._id].source_ids.push(key);
        });
      }
      return tagMappingTable;
    },
    cameraIds() {
      return this.streams.map((stream) => stream.camera_id);
    },
  },
  async mounted() {
    await Promise.all([
      this.loadPlanConfig(),
      this.loadHierarchyData(),
      this.loadStreamMeta(),
      this.loadSectionMasks(),
      this.loadProcesses(),
      this.loadProcessBasedSectionMaskDateRanges(),
    ]);
    this.setMappingTable();

    setTimeout(() => {
      this.dataLoaded = true;
      this.scrollToPlannerItem();

      this.$nextTick(() => {
        document.body.addEventListener("click", this.tagSelectionListener);
        document.body.addEventListener("copy", this.tagCopyListener);
      });
    }, 100);
  },
  unmounted() {
    document.body.removeEventListener("click", this.tagSelectionListener);
    document.body.removeEventListener("copy", this.tagCopyListener);
  },
  methods: {
    loadStreamMeta() {
      const { customer_name, site_id } = this.route.params;
      return StreamRepository.loadStreams(customer_name as string, site_id as string)
        .then((data) => {
          this.streams = data;
        })
        .catch((error) => {
          logger.error(error);
          alert("Unable to load streams");
        });
    },
    changeMode(mode: PlannerMode) {
      const { customer_name, site_id } = this.route.params;
      if (["visitor", "tracker", "revision"].includes(mode)) {
        this.router.push({ path: `/planner/${customer_name}/${site_id}`, query: { mode } });
      }
    },
    convertAttachedProcessesProperty(tags: HierarchyTagStore[]): HierarchyTag[] {
      return tags.map((obj) => {
        return {
          ...obj,
          attached_processes: obj.attached_processes && {
            included: this.processClasses.filter((process) =>
              obj.attached_processes?.includes(process.encodedLabel),
            ),
            excluded: this.processClasses.filter(
              (process) => !obj.attached_processes?.includes(process.encodedLabel),
            ),
          },
        };
      });
    },
    loadSectionMasks() {
      const { customer_name, site_id } = this.route.params;
      return SectionMasksRepository.loadSectionMasks(customer_name as string, site_id as string)
        .then((data) => {
          this.maskList = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to retrieve section masks");
          }
        });
    },
    loadHierarchyData() {
      const { customer_name, site_id } = this.route.params;
      return HierarchyRepository.loadHierarchyTags(customer_name as string, site_id as string)
        .then((data) => {
          this.hierarchyTags = this.convertAttachedProcessesProperty(data);
          this.partitionHierarchyTags();
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to retrieve hierarchy data");
          }
        });
    },
    loadProcesses() {
      const { customer_name, site_id } = this.route.params;
      return ValidationRepository.loadOpsReviewSite(customer_name as string, site_id as string)
        .then((data) => {
          this.processes = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load processes");
          }
        });
    },
    loadProcessBasedSectionMaskDateRanges() {
      const { customer_name, site_id } = this.route.params;
      return SectionMasksRepository.loadProcessBasedSectionMaskDateRanges(
        customer_name as string,
        site_id as string,
      )
        .then((data) => {
          this.sectionMaskDateRange = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
          }
        });
    },
    tagSelectionListener(e: MouseEvent) {
      if (!e.target) {
        return;
      }

      const target = e.target as HTMLElement;
      if (target.dataset.tagId) {
        this.focusedHierarchyTagId = target.dataset.tagId;
      }

      const tagId = (target.closest("[data-tag-id]") as HTMLElement)?.dataset?.tagId;
      if (tagId) {
        this.focusedHierarchyTagId = tagId;
      } else {
        this.focusedHierarchyTagId = undefined;
      }
    },
    tagCopyListener() {
      if (!this.focusedHierarchyTagId) {
        return;
      }
      const tag = this.partitionedTags
        .flatMap((tags) => tags.tags)
        .find((tag) => tag._id === this.focusedHierarchyTagId);
      if (!tag) {
        return;
      }
      this.copiedHierarchyTag = tag;
      this.showToast("success", `Tag copied: [${tag?.type}] ${tag?.name}`);
    },
    async saveData() {
      if (this.saveLoading) {
        return;
      }
      this.saveLoading = true;
      try {
        this.orphanMasks = await this.getOrphanMasks(structuredClone(this.maskList));
        if (this.orphanMasks.length > 0) {
          this.orphanMasksModal = true;
          return;
        }
        this.dataLoaded = false;
        if (this.plannerItemsChanges.size > 0) {
          await this.savePlannerItemChanges();
          this.plannerItemsChanges = new Set<string>();
        }

        await this.saveHierarchyData();
        await this.saveSectionMasks();
        await this.applyProcessToSectionRemapping();
        await this.applyProcessRemapping();

        this.queryClient.removeQueries([
          "hierarchy-tags",
          this.currentCustomerName,
          this.currentSiteId,
        ]);
        this.queryClient.setQueryData(
          ["critical-path-layout", this.currentCustomerName, this.currentSiteId],
          null,
        );
        this.queryClient.removeQueries([
          "critical-path",
          this.currentCustomerName,
          this.currentSiteId,
        ]);
        this.hasChanges = false;
      } catch (error) {
        logger.error(error);
        alert("Unable to save data");
      } finally {
        setTimeout(() => {
          this.dataLoaded = true;
          this.saveLoading = false;
        }, 500);
      }
    },
    saveAndDeprecateMasks() {
      this.maskList = this.maskList.filter(
        (mask) =>
          !mask.section_id ||
          this.sectionMaskCombinations.combinations.some(
            (combination) =>
              combination.building_id === mask.building_id &&
              combination.level_id === mask.level_id &&
              combination.section_id === mask.section_id &&
              combination.level_split_id === mask.level_split_id,
          ),
      );
      this.saveData();
    },
    async applyProcessToSectionRemapping() {
      const { customer_name, site_id } = this.route.params;
      ValidationRepository.remapOpsReviewProcessesToSectionMasks(
        customer_name as string,
        site_id as string,
        {
          camera_ids: this.cameraIds,
          start_date: null,
          end_date: null,
        },
      )
        .then((data) => {
          if (
            data.level_to_mask_mapping_changes.length > 0 ||
            data.level_to_mask_mapping_not_applicable.length > 0 ||
            data.section_changes_planned.length > 0 ||
            data.conflict_changes_planned.length > 0
          ) {
            const notification = `Applied level-to-mask-changes: ${`${
              data.level_to_mask_mapping_changes.length
            }/${
              data.level_to_mask_mapping_changes.length +
              data.level_to_mask_mapping_not_applicable.length
            }`}\nOpen section changes: ${
              data.section_changes_planned.length
            }\nOpen conflict changes: ${data.conflict_changes_planned.length}`;

            if (
              data.section_changes_planned.length > 0 ||
              data.conflict_changes_planned.length > 0 ||
              data.level_to_mask_mapping_not_applicable.length > 0
            ) {
              this.showToast("warning", notification);
            } else {
              this.showToast("success", notification);
            }
          }
        })
        .catch((error) => {
          logger.error(error);
          const errorMessage = this.getErrorMessageDetail(error, "Remapping failed");
          useCustomToast().error(errorMessage);
        });
    },
    async saveSectionMasks() {
      const { customer_name, site_id } = this.route.params;
      try {
        const response = await SectionMasksRepository.saveSectionMasks(
          customer_name as string,
          site_id as string,
          this.maskList,
        );
        this.maskList = response;
      } catch (error) {
        alert("Unable to save section masks.");
        logger.error(error);
      }
    },
    async savePlannerItemChanges() {
      const { customer_name, site_id } = this.route.params;
      if (!this.planConfig) {
        return;
      }

      const { planner_comments, ...planConfig } = this.planConfig;

      PlannerRepository.createNewRevision(
        customer_name as string,
        site_id as string,
        {
          ...planConfig,
          planner_items: (planConfig.planner_items as PlannerItemWithChildren[]).map(
            ({ children, ...rest }) => rest,
          ),
        },
        [],
      )
        .then((newPlanConfig) => {
          this.planConfig = newPlanConfig;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    getErrorMessageDetail(error: unknown, fallbackMessage: string) {
      return (isAxiosError(error) && error.response?.data?.detail) || fallbackMessage;
    },
    async saveHierarchyData() {
      const tagList: HierarchyTagStore[] = Object.values(this.tagMappingTable).map((obj) => {
        if (obj.attached_processes) {
          return {
            ...obj,
            attached_processes: obj.attached_processes.included.map(
              (process) => process.encodedLabel,
            ),
          };
        }
        return obj as HierarchyTagStore;
      });
      const { customer_name, site_id } = this.route.params;

      try {
        const response = await HierarchyRepository.saveHierarchyTags(
          customer_name as string,
          site_id as string,
          tagList,
        );
        this.hierarchyTags = this.convertAttachedProcessesProperty(response.tags);
        this.resolveIdMappingForMasks(response.id_map, response.splits_id_map);
        this.partitionHierarchyTags();
        this.setMappingTable();
      } catch (error) {
        const errorMessage = this.getErrorMessageDetail(error, "Unable to save hierarchy tags");
        useCustomToast().error(errorMessage);
        logger.error(error);
      }
    },
    resolveIdMappingForMasks(idMap: Record<string, string>, splitIdMap: Record<string, string>) {
      this.maskList.forEach((mask) => {
        if (mask.building_id && mask.building_id in idMap) {
          mask.building_id = idMap[mask.building_id];
        }
        if (mask.level_id && mask.level_id in idMap) {
          mask.level_id = idMap[mask.level_id];
        }
        if (mask.section_id && mask.section_id in idMap) {
          mask.section_id = idMap[mask.section_id];
        }
        if (mask.level_split_id && mask.level_split_id in splitIdMap) {
          mask.level_split_id = splitIdMap[mask.level_split_id];
        }

        return mask;
      });
    },
    loadPlanConfig() {
      const { customer_name, site_id } = this.route.params;
      return PlannerRepository.loadPlanConfig(customer_name as string, site_id as string)
        .then((planConfig) => {
          this.planConfig = planConfig;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load plan");
          }
        });
    },
    partitionHierarchyTags() {
      const partitionedTags: PartitionedTags[] = [];
      for (const section of this.editableHierarchyTypes) {
        const filteredTags: HierarchyTag[] = this.hierarchyTags.filter(
          (item) => item.type === section,
        );

        partitionedTags.push({
          type: section,
          tags: filteredTags,
        });
      }
      this.partitionedTags = partitionedTags;
    },
    setMappingTable() {
      const mappingTable: Record<string, HierarchyTag[]> = {};
      if (this.planConfig) {
        // Initialize
        this.planConfig.planner_items.forEach((obj) => {
          mappingTable[obj.source_id] = [];
        });
        this.partitionedTags.forEach((tagSection) => {
          tagSection["tags"].forEach((tag) => {
            tag["source_ids"].forEach((sourceId) => {
              if (sourceId in mappingTable) {
                mappingTable[sourceId].push(tag);
              } else {
                tag["source_ids"] = tag["source_ids"].filter((id) => id !== sourceId);
              }
            });
          });
        });
      }
      this.mappingTable = mappingTable;
    },
    closeModal() {
      this.openEditHierarchyTagModal = false;
    },
    mapPartitionedHierarchyGroups() {
      const hierarchyTags = {} as Record<string, HierarchyTag>;
      for (const partitionedTag of this.partitionedTags) {
        for (const tag of partitionedTag.tags) {
          // Reset source ids
          tag.source_ids = [];
          hierarchyTags[tag._id] = tag;
        }
      }
      return hierarchyTags;
    },
    getTagColor(type: HierarchyType) {
      return this.colorConfigTag[type];
    },
    getTagBorderStyle(type: HierarchyType) {
      return this.borderColorConfigTag[type];
    },
    getBuildingElementColor(buildingElement: BuildingElement) {
      return this.colorConfigBuildingElement[buildingElement];
    },
    capitalizeSectionType(name: HierarchyType) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    formatTagText(tag: HierarchyTag) {
      return (
        `${tag.number} - ` + (tag.name ? `[${tag.name}]` : this.capitalizeSectionType(tag.type))
      );
    },
    formatTimestampDisplay(date: Date) {
      return format(date, "dd.MM.yyyy HH:mm");
    },
    findSmallestPossibleNumber(numbers: number[]) {
      let smallestNumber = 1;
      while (numbers.includes(smallestNumber)) {
        smallestNumber++;
      }
      return smallestNumber;
    },
    addHierarchyTag(type: HierarchyType) {
      const { customer_name, site_id } = this.route.params;
      const hierarchySection = this.partitionedTags.find((x) => x.type === type);
      if (!hierarchySection) {
        throw new Error("missing hierarchy section");
      }
      const hierarchyTagsForType = hierarchySection.tags;
      const numbers: number[] = hierarchyTagsForType.map((a: { number: number }) => a.number);

      const newTag = {
        _id: "oculai-" + uuidv4(),
        source_ids: [],
        customer_name: customer_name,
        site_id: site_id,
        type: type,
        number: this.findSmallestPossibleNumber(numbers),
        name: "",
        attached_processes: type === "component" ? this.initializeAttachedProcesses() : null,
        splits: null,
      } as HierarchyTag;

      hierarchyTagsForType.push(newTag);

      this.currentTag = newTag;
      this.openEditHierarchyTagModal = true;

      this.$nextTick(() => {
        (this.$refs.tagNameInput as HTMLInputElement)?.focus();
      });
    },
    initializeAttachedProcesses(): AttachedProcesses {
      return {
        included: [],
        excluded: this.processClasses,
      } as AttachedProcesses;
    },
    getTreeNodeByItemId(id: string) {
      function traverse(
        nodes: PlannerItemWithChildren[],
        id: string,
      ): PlannerItemWithChildren | null {
        for (const node of nodes) {
          if (node._id === id) {
            return node;
          }
          if (node.children) {
            const found = traverse(node.children, id);
            if (found) {
              return found;
            }
          }
        }
        return null;
      }

      return traverse(this.plannerItemsTree, id);
    },
    deleteTag(id: string, type: HierarchyType) {
      const hierarchySection = this.partitionedTags.find((x) => x.type === type);
      if (!hierarchySection) {
        throw new Error("missing hierarchy section");
      }
      const sectionMasksToDeprecate = this.maskList.filter(
        (mask) => mask.building_id === id || mask.level_id === id || mask.section_id === id,
      );
      if (sectionMasksToDeprecate.length > 0) {
        if (
          !window.confirm(
            `Active Section Masks are attached. If you delete the tag the masks will be deprecated`,
          )
        ) {
          return;
        }
        sectionMasksToDeprecate.forEach((mask) => {
          this.deleteSectionMask(mask);
        });
      }

      const removeIdx = hierarchySection.tags.map((item: HierarchyTag) => item._id).indexOf(id);
      hierarchySection.tags.splice(removeIdx, 1);

      Object.keys(this.mappingTable).forEach((key) => {
        this.mappingTable[key] = this.mappingTable[key].filter((obj) => obj._id !== id);
      });
    },
    validateTagMove(evt: MoveEvent<HierarchyTag>) {
      const type = evt.draggedContext["element"]["type"];
      const tagId = evt.draggedContext["element"]["_id"];
      const tag = evt.draggedContext["element"];
      const subTreeItem = evt.relatedContext.component.componentData?.props.item as PlannerItem;

      const isWithinSameComponent = evt.from === evt.to;

      if (
        !isWithinSameComponent &&
        evt.relatedContext.list.some((tag: HierarchyTag) => {
          return tag.type === type && !this.isInherited(subTreeItem, tag);
        })
      ) {
        this.triggerValidationError("Duplicate Type.");
        return false;
      }

      if (subTreeItem) {
        const parentError = this.checkSameTypeForParents(subTreeItem, type, tagId);
        if (parentError) {
          this.triggerValidationError(parentError);
          return false;
        }
        if (type === "component") {
          if (!tag.attached_processes || tag.attached_processes.included.length === 0) {
            this.triggerValidationError("Assign processes to tag before assignment!");
            return false;
          }
          const componentNestingError = this.checkValidComponentNesting(subTreeItem, tag);
          if (componentNestingError) {
            this.triggerValidationError(componentNestingError);
            return false;
          }
          const componentProcessOverlapError = this.checkComponentProcessClassOverlap(
            subTreeItem,
            tag,
          );
          if (componentProcessOverlapError) {
            this.triggerValidationError(componentProcessOverlapError);
            return false;
          }
        }
      }

      this.resetValidationError();
    },

    handleDragEnd(group: PartitionedTags) {
      group.tags.forEach((tag, i) => {
        tag.number = i + 1;
      });

      this.resetValidationError();
    },
    triggerValidationError(message: string) {
      this.errorMessage = message;
      this.validationError = true;
    },
    resetValidationError() {
      this.errorMessage = "";
      this.validationError = false;
    },
    checkSameTypeForParents(
      plannerItem: PlannerItem,
      type: HierarchyType,
      tagId: string,
    ): string | null {
      while (plannerItem) {
        const parentId = plannerItem.parent_id;
        if (this.plannerItemsIdMap && parentId) {
          const parent = this.plannerItemsIdMap[parentId];
          if (["section", "component"].includes(type)) {
            if (this.hasSameTagMapped(parent.source_id, tagId)) {
              return `${this.capitalizeSectionType(type)} Tag Duplicate in Parent.`;
            } else {
              plannerItem = parent;
            }
          } else {
            if (this.hasSameTypeMapped(parent.source_id, type)) {
              return "Same Type in Parent.";
            } else {
              plannerItem = parent;
            }
          }
        } else {
          return null;
        }
      }
      return null;
    },
    checkValidComponentNesting(plannerItem: PlannerItem, tag: HierarchyTag): string | null {
      const parentTags = this.getParentTags(plannerItem);
      if (parentTags) {
        const parentComponentTag = parentTags.find((parentTag) => parentTag.type === "component");
        if (parentComponentTag) {
          if (
            !tag.attached_processes?.included.every((item) =>
              parentComponentTag.attached_processes?.included.includes(item),
            )
          ) {
            return "Invalid Component Nesting";
          }
        }
      }

      return null;
    },
    checkComponentProcessClassOverlap(plannerItem: PlannerItem, tag: HierarchyTag) {
      if (!this.plannerItemsIdMap) {
        return null;
      }
      const parentId = plannerItem.parent_id;
      if (!parentId) {
        return null;
      }

      const sectionMaskCombinationSet = this.sectionMaskPossibilityMap[plannerItem.source_id];
      if (!sectionMaskCombinationSet || sectionMaskCombinationSet.size === 0) {
        return null;
      }
      const sectionMaskCombinationToCheck = sectionMaskCombinationSet.values().next().value;
      const mappingTable = this.mappingTable;
      const sectionMaskPossibilityMap = this.sectionMaskPossibilityMap;
      let overlapError = null;

      function traverse(parent: PlannerItemWithChildren, node: PlannerItemWithChildren) {
        if (
          node.source_id !== plannerItem.source_id &&
          sectionMaskPossibilityMap[node.source_id] &&
          sectionMaskPossibilityMap[node.source_id].has(sectionMaskCombinationToCheck)
        ) {
          const componentTagToCompare = mappingTable[node.source_id].find(
            (item) => item.type === "component",
          );
          if (
            componentTagToCompare &&
            componentTagToCompare._id !==
              mappingTable[parent.source_id].find((item) => item.type === "component")?._id
          ) {
            const encodedLabelList = componentTagToCompare.attached_processes?.included.map(
              (item) => item.encodedLabel,
            );
            if (
              tag.attached_processes?.included.some((item) =>
                encodedLabelList?.includes(item.encodedLabel),
              )
            ) {
              overlapError = "Found overlapping processes in sibling or child item for same mask";
            }
          }
        }
        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            traverse(node, child);
          }
        }
      }

      const parentNode = this.getTreeNodeByItemId(parentId);
      if (parentNode && parentNode.children && parentNode.children.length > 0) {
        for (const child of parentNode.children) {
          traverse(parentNode, child);
        }
      }
      return overlapError;
    },
    getParentTags(plannerItem: PlannerItem) {
      if (this.plannerItemsIdMap) {
        const parentId = plannerItem.parent_id;
        if (parentId) {
          const parentSourceId = this.plannerItemsIdMap[parentId].source_id;
          return this.mappingTable[parentSourceId];
        }
      }
      return null;
    },

    hasSameTypeMapped(sourceId: string, type: string): boolean {
      return this.mappingTable[sourceId]?.some((obj) => obj.type === type);
    },
    hasSameTagMapped(sourceId: string, id: string): boolean {
      return this.mappingTable[sourceId]?.some((obj) => obj._id === id);
    },

    removeTagInHierarchy(removeEvent: { node: PlannerItemWithChildren; tag: HierarchyTag }) {
      const sourceId = removeEvent.node.source_id;
      const tag = removeEvent.tag;
      this.mappingTable[sourceId] = this.mappingTable[sourceId].filter((item) => item !== tag);

      this.removeTagFromChildren(removeEvent.node, tag);
      this.repopulateIfParentSameTagType(removeEvent.node, tag);
    },
    sortProcessList() {
      const listTypes = ["included", "excluded"] as ("included" | "excluded")[];

      listTypes.forEach((listType) =>
        this.currentTag.attached_processes?.[listType].sort((a, b) => {
          const indexA = this.buildingElements.indexOf(a.buildingElement);
          const indexB = this.buildingElements.indexOf(b.buildingElement);
          return indexA - indexB;
        }),
      );
    },
    selectComponentPreset(preset: ComponentPreset) {
      let encodedLabelList: number[] = [];
      if (preset.type === "single") {
        encodedLabelList = preset.classes;
      }
      if (preset.type === "range" && preset.classes.length >= 2) {
        for (let i = preset.classes[0]; i <= preset.classes[1]; i++) {
          encodedLabelList.push(i);
        }
      }
      if (encodedLabelList) {
        const included = this.processClasses.filter((process) =>
          encodedLabelList.includes(process.encodedLabel),
        );
        this.calculateProcessValidationErrors(this.processClasses);
        if (this.processValidationErrors.length === 0) {
          this.currentTag.attached_processes = {
            included: included,
            excluded: this.processClasses.filter(
              (process) => !encodedLabelList.includes(process.encodedLabel),
            ),
          };
        }
      }
    },
    getSectionMaskCombinations(tagList: HierarchyTag[] | undefined): string[] | null {
      if (!tagList) {
        return null;
      }

      const sectionTag = tagList.find((tag) => tag.type === "section");
      if (!sectionTag) {
        return null;
      }

      const buildingTag = tagList.find((tag) => tag.type === "building");
      const levelTag = tagList.find((tag) => tag.type === "level");

      if (levelTag?.splits) {
        const componentTag = tagList.find((tag) => tag.type === "component");

        const combinations = levelTag.splits.reduce((acc, split) => {
          // Take overlap between split and component tag into consideration (if any)
          if (
            !componentTag ||
            (componentTag.attached_processes &&
              componentTag.attached_processes.included.some((process) =>
                split.processes.includes(process.encodedLabel),
              ))
          ) {
            acc.push(
              JSON.stringify({
                building_id: buildingTag ? buildingTag._id : null,
                level_id: levelTag._id,
                section_id: sectionTag._id,
                level_split_id: split.id,
              }),
            );
          }
          return acc;
        }, [] as string[]);

        return combinations;
      }

      const combination = {
        building_id: buildingTag ? buildingTag._id : null,
        level_id: levelTag ? levelTag._id : null,
        section_id: sectionTag._id,
        level_split_id: null,
      };
      return [JSON.stringify(combination)];
    },
    populateChildrenWithTag(node: PlannerItemWithChildren, tag: HierarchyTag) {
      const hasIdenticalTag = this.mappingTable[node.source_id].some((item) => item === tag);
      this.mappingTable[node.source_id] = this.mappingTable[node.source_id].filter(
        (item) => item.type !== tag.type || item === tag,
      );

      if (!hasIdenticalTag && node.tracking_enabled) {
        this.mappingTable[node.source_id].push(tag);
        this.sortMappedTagsBySourceId(node.source_id);
      }
      if (this.isMappingLeafNode(node)) {
        return;
      }
      node.children?.forEach((child) => this.populateChildrenWithTag(child, tag));
    },
    repopulateIfParentSameTagType(node: PlannerItemWithChildren, tag: HierarchyTag) {
      if (node && node.parent_id && this.plannerItemsIdMap) {
        const parentSourceId = this.plannerItemsIdMap[node.parent_id].source_id;
        const sameTypeTag = this.mappingTable[parentSourceId].find(
          (item) => item.type === tag.type,
        );
        if (sameTypeTag) {
          this.populateChildrenWithTag(node, sameTypeTag);
        }
      }
    },
    removeTagFromChildren(node: PlannerItemWithChildren, tag: HierarchyTag) {
      this.mappingTable[node.source_id] = this.mappingTable[node.source_id].filter(
        (item) => item !== tag,
      );
      if (this.isMappingLeafNode(node)) {
        return;
      }
      node.children?.forEach((child) => this.removeTagFromChildren(child, tag));
    },
    sortMappedTagsBySourceId(sourceId: string) {
      this.mappingTable[sourceId].sort((a, b) => {
        const indexA = this.editableHierarchyTypes.indexOf(a.type);
        const indexB = this.editableHierarchyTypes.indexOf(b.type);
        return indexA - indexB;
      });
    },
    postProcessTagMove(input: { item: PlannerItemWithChildren; event: ChangeEvent<HierarchyTag> }) {
      const evt = input.event;
      const node = input.item;
      if ("added" in evt) {
        const tag = evt.added.element;
        this.sortMappedTagsBySourceId(node.source_id);
        this.populateChildrenWithTag(node, tag);
      }
      if ("removed" in evt) {
        const tag = evt.removed.element;
        this.removeTagFromChildren(node, tag);
        this.repopulateIfParentSameTagType(node, tag);
      }
    },
    isInherited(item: PlannerItem, tag: HierarchyTag): boolean {
      if (
        item &&
        item.parent_id &&
        this.plannerItemsIdMap &&
        item.parent_id in this.plannerItemsIdMap
      ) {
        return this.mappingTable[this.plannerItemsIdMap[item.parent_id].source_id].some(
          (item) => item._id === tag._id,
        );
      }
      return false;
    },
    deleteSectionMask(maskToDelete: SectionMask) {
      this.maskList = this.maskList.filter((mask) => mask._id !== maskToDelete._id);
    },
    updateSectionMasks(change: { update: SectionMask[]; delete: SectionMask[] }) {
      change.update.forEach((sectionMask) => {
        const index = this.maskList.findIndex((item) => item._id === sectionMask._id);
        if (index !== -1) {
          this.maskList[index] = sectionMask;
        } else {
          this.maskList.push(sectionMask);
        }
      });
      change.delete.forEach((item) => {
        this.deleteSectionMask(item);
      });
    },
    focusValidityRangeMask(masks: SectionMask[], toggle = false, resetLevelId = null) {
      if (masks.length === 0) return;

      let maskList = [] as SectionMask[];

      if (resetLevelId) {
        maskList = this.maskList.map((item) => {
          if (item.level_id === resetLevelId) {
            item.show_on_canvas = false;
          }
          return item;
        });
      }

      masks.forEach((mask) => {
        maskList = this.maskList.map((item) => {
          if (
            item.camera_id === mask.camera_id &&
            item.building_id === mask.building_id &&
            item.level_id === mask.level_id &&
            item.section_id === mask.section_id
          ) {
            if (item._id === mask._id) {
              item.show_on_canvas = toggle ? !item.show_on_canvas : true;
            } else {
              item.show_on_canvas = false;
            }
          }
          return item;
        });
      });
      this.maskList = maskList;
    },
    async getOrphanMasks(masksToCheck: SectionMask[]) {
      this.orphanMasks = [];

      return masksToCheck.filter(
        (mask) =>
          mask.section_id &&
          !this.sectionMaskCombinations.combinations.some(
            (combination) =>
              combination.building_id === mask.building_id &&
              combination.level_id === mask.level_id &&
              combination.section_id === mask.section_id &&
              combination.level_split_id === mask.level_split_id,
          ),
      );
    },
    formatMaskName(mask: SectionMask) {
      const tags: HierarchyTag[] = [];
      if (mask.building_id) {
        tags.push(this.tagMap[mask.building_id]);
      }
      if (mask.level_id) {
        tags.push(this.tagMap[mask.level_id]);
      }
      if (mask.section_id) {
        tags.push(this.tagMap[mask.section_id]);
      }
      if (mask.level_split_id) {
        tags.push(this.tagMap[mask.level_split_id]);
      }

      const tagNames = tags.filter((tag) => tag).map((obj) => this.formatTagText(obj));
      return tagNames.join(" | ");
    },

    collectSubTreeSourceIds(subTree: PlannerItemWithChildren) {
      const sourceIds: string[] = [];

      function traverse(node: PlannerItemWithChildren) {
        if (node.source_id) {
          sourceIds.push(node.source_id);
        }
        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }

      traverse(subTree);
      return sourceIds;
    },

    toggleTrackingEnabled(node: PlannerItemWithChildren) {
      const sourceIds = this.collectSubTreeSourceIds(node);
      const parentTags = this.getParentTags(node);

      const trackingStatusToSet = !node.tracking_enabled;

      sourceIds.forEach((sourceId) => {
        if (this.plannerItemsIndexMap && sourceId in this.plannerItemsIndexMap) {
          const idx = this.plannerItemsIndexMap[sourceId];
          const item = this.planConfig?.planner_items[idx];
          if (item && item.tracking_enabled !== trackingStatusToSet) {
            item.tracking_enabled = trackingStatusToSet;

            if (!trackingStatusToSet) {
              this.mappingTable[sourceId] = [];
            } else {
              parentTags?.forEach((tag) => {
                this.mappingTable[sourceId].push(tag);
              });
            }

            // Track changes
            if (this.plannerItemsChanges.has(sourceId)) {
              this.plannerItemsChanges.delete(sourceId);
            } else {
              this.plannerItemsChanges.add(sourceId);
            }
          }
        }
      });
    },
    async applyProcessRemapping() {
      if (this.remapLoading) {
        return;
      }
      this.remapLoading = true;
      const { customer_name, site_id } = this.route.params;
      ValidationRepository.remapOpsReviewProcessesToPlan(customer_name as string, site_id as string)
        .then((data) => {
          this.processes = data;
        })
        .catch((error) => {
          logger.error(error);
          const errorMessage = this.getErrorMessageDetail(
            error,
            "Unable to remap planner processes",
          );
          useCustomToast().error(errorMessage);
        })
        .finally(() => {
          this.remapLoading = false;
        });
    },
    scrollToPlannerItem() {
      const sectionMaskId = this.route.query.section_mask_id;
      if (!sectionMaskId) {
        return;
      }
      const sectionMask = this.maskList.find((sectionMask) => sectionMask._id === sectionMaskId);
      if (!sectionMask) {
        return;
      }

      const buildingId = sectionMask.building_id;
      const levelId = sectionMask.level_id;
      const sectionId = sectionMask.section_id;

      const plannerItem = this.planConfig?.planner_items.find(({ source_id }) => {
        const hierarchyTags = this.mappingTable[source_id];

        if (!hierarchyTags) {
          return false;
        }

        const hasBuildingId = buildingId
          ? hierarchyTags.find((hierarchyTag) => hierarchyTag._id === buildingId)
          : hierarchyTags.every((hierarchyTag) => hierarchyTag.type !== "building");
        const hasLevelId = levelId
          ? hierarchyTags.find((hierarchyTag) => hierarchyTag._id === levelId)
          : hierarchyTags.every((hierarchyTag) => hierarchyTag.type !== "level");
        const hasSectionId = hierarchyTags.find((hierarchyTag) => hierarchyTag._id === sectionId);
        return hasBuildingId && hasLevelId && hasSectionId;
      });

      if (plannerItem) {
        document.getElementById(plannerItem._id)?.scrollIntoView({ behavior: "smooth" });
      }
    },
    calculateProcessValidationErrors(processClasses: ProcessClass[]) {
      const hierarchyTag = {
        ...this.currentTag,
        attached_processes: {
          ...this.currentTag.attached_processes,
          included: processClasses,
        },
      } as HierarchyTag;

      const plannerItems =
        this.planConfig?.planner_items.filter(({ source_id }) =>
          this.mappingTable[source_id]?.some(({ _id }) => _id === this.currentTag._id),
        ) || [];

      this.processValidationErrors = plannerItems
        .map((plannerItem) => ({
          plannerItem,
          error: this.checkComponentProcessClassOverlap(plannerItem, hierarchyTag),
        }))
        .filter((item) => item.error);
    },
    validateProcessMove(evt: MoveEvent<ProcessClass>) {
      const processClass = evt.draggedContext.element;
      this.calculateProcessValidationErrors([
        ...(this.currentTag.attached_processes?.included || []),
        processClass,
      ]);
      return this.processValidationErrors.length === 0;
    },
    moveIncludedProcessItem() {
      this.processValidationErrors = [];
    },
    isMappingLeafNode(node: PlannerItemWithChildren) {
      return (
        !node.children ||
        node.children.length === 0 ||
        node.children.every((child: PlannerItemWithChildren) => !child.tracking_enabled)
      );
    },
    addLevelTagSplit() {
      if (this.currentTag.splits) {
        this.currentTag.splits = [
          ...this.currentTag.splits,
          {
            id: "oculai-" + uuidv4(),
            name: `Split ${this.currentTag.splits.length + 1}`,
            processes: [],
          },
        ];

        return;
      }

      this.currentTag.splits = [
        {
          id: "oculai-" + uuidv4(),
          name: "Split 1",
          processes: this.processClasses.map((process) => process.encodedLabel),
        },
        {
          id: "oculai-" + uuidv4(),
          name: "Split 2",
          processes: [],
        },
      ];
    },
    removeLevelTagSplit(index: number) {
      if (this.currentTag.splits?.length == 2) {
        this.currentTag.splits = null;
      } else if (this.currentTag.splits) {
        const updatesSplits = this.currentTag.splits.filter((_, i) => i !== index);
        const remainingProcesses = this.currentTag.splits[index].processes;
        updatesSplits[0].processes.push(...remainingProcesses);

        this.currentTag.splits = updatesSplits;
      }
    },
  },
  setup() {
    const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
    const processClasses = useProcessClasses();
    const project = useCurrentProject();
    const route = useRoute();
    const router = useRouter();
    const queryClient = useQueryClient();
    return {
      showSaveBeforeLeaveConfirmationModal,
      processClasses,
      project,
      route,
      router,
      queryClient,
    };
  },
});
</script>

<style>
.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>

<style scoped>
.blockMove {
  cursor: default;
}
</style>
