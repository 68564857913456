import { EventEmitter } from "events";
import * as fabric from "fabric";
import { markRaw } from "vue";
import { Sequence } from "@/views/person_gad/types";
import { Annotation } from "../Annotation";
import { GroupColor } from "../colors";

const CANVAS_META_KEYS = {
  IS_DRAWING: "isDrawing",
  IS_DRAGGING: "isDragging",
  IS_SELECTING: "isSelecting",
  LAST_POS_X: "lastPosX",
  LAST_POS_Y: "lastPosY",
};

fabric.Text.prototype.set({
  _getNonTransformedDimensions() {
    return new fabric.Point(this.width, this.height).scalarAdd(this.padding);
  },
  _calculateCurrentDimensions() {
    return fabric.util.transformPoint(
      this._getTransformedDimensions(),
      this.getViewportTransform(),
      true,
    );
  },
});

type CanvasEvents =
  | "contextmenu"
  | "annotation:deleted"
  | "annotation:created"
  | "annotation:scaling"
  | "annotation:scaled";

export default class CanvasService {
  private cleanListeners: (() => void)[] = [];
  private readonly canvas: fabric.Canvas;
  private readonly eventEmitter = new EventEmitter();
  private readonly CUSTOM_EVENTS = ["annotation:scaling", "annotation:scaled"] as const;

  constructor(canvas: fabric.Canvas) {
    this.canvas = canvas;

    this._defineCustomEvents();
    this._pinTextObjectsToFront();
  }

  private _defineCustomEvents() {
    this.CUSTOM_EVENTS.forEach((event) => {
      this.canvas.on(event, (payload) => {
        this.eventEmitter.emit(event, payload);
      });
    });
  }

  private _moveTextObjectsToFront() {
    const textObjects = this.canvas.getObjects().filter((object) => object instanceof fabric.Text);

    textObjects.forEach((textObject) => {
      this.canvas.bringObjectToFront(textObject);
    });
  }

  private _pinTextObjectsToFront() {
    const events = ["object:added", "object:modified", "dragend"] as const;

    events.forEach((event) => {
      this.canvas.on(event, this._moveTextObjectsToFront.bind(this));
    });
  }

  private _handleCanvasZoom(event: fabric.TPointerEventInfo<WheelEvent>) {
    const delta = event.e.deltaY;
    const originalScale = this.canvas.get("data").originalScale;
    let zoom = this.canvas.getZoom();

    zoom *= 0.999 ** delta;
    zoom = Math.min(15 * originalScale, zoom);
    zoom = Math.max(0.8 * originalScale, zoom);

    this.canvas.zoomToPoint(new fabric.Point({ x: event.e.offsetX, y: event.e.offsetY }), zoom);

    this.canvas.getObjects().forEach((object) => {
      if (object instanceof Annotation) {
        const annotationText = object.item(1);
        annotationText.scale(1 / zoom);
      }

      if (object.get("data")?.type === "pct_bbox" && object instanceof fabric.Group) {
        const bboxText = object.item(1);
        bboxText.scale(1 / zoom);
      }
    });

    event.e.preventDefault();
    event.e.stopPropagation();
  }

  private _onStartRectDraw(event: fabric.TPointerEventInfo) {
    event.e.preventDefault();
    event.e.stopPropagation();
    event.e.stopImmediatePropagation();

    const pointer = this.canvas.getScenePoint(event.e);

    const annotation = new Annotation({
      left: pointer?.x,
      top: pointer?.y,
      fill: "rgba(128, 128, 128, 0.5)",
      data: {
        isNew: true,
        originalX: pointer.x,
        originalY: pointer.y,
        type: "annotation",
        labels: {
          passive: false,
          support: false,
        },
      },
    });

    this.canvas.setCursor("crosshair");
    this.canvas.add(annotation);
    this.canvas.setActiveObject(annotation);
  }

  private _onRectDraw(event: fabric.TPointerEventInfo) {
    const annotation = this.canvas.getActiveObject();

    if (
      !(annotation instanceof Annotation) ||
      !annotation.data.originalX ||
      !annotation.data.originalY
    ) {
      return;
    }

    const scenePoint = this.canvas.getScenePoint(event.e);
    const viewportPoint = this.canvas.getViewportPoint(event.e);

    const objectMeta = {
      left: Math.round(Math.min(scenePoint.x, annotation.data.originalX)),
      top: Math.round(Math.min(scenePoint.y, annotation.data.originalY)),
      width: Math.round(Math.abs(scenePoint.x - annotation.data.originalX)),
      height: Math.round(Math.abs(scenePoint.y - annotation.data.originalY)),
    };

    annotation.set(objectMeta);
    annotation.setCoords();

    this.canvas.setCursor("crosshair");
    this.canvas.renderAll();

    this.eventEmitter.emit("annotation:scaling", { x: viewportPoint.x, y: viewportPoint.y });
  }

  private _onEndRectDraw() {
    const activeObject = this.canvas.getActiveObject();

    if (!(activeObject instanceof Annotation)) {
      return;
    }

    this.eventEmitter.emit("annotation:scaled", activeObject);
    this.eventEmitter.emit("annotation:created", activeObject);

    activeObject.data.isNew = false;
  }

  private _onStartPanMove(event: fabric.TPointerEventInfo) {
    const mouseEvent = event.e as MouseEvent;
    const canvasData = this.canvas.get("data");

    this.canvas.setCursor("grab");
    canvasData[CANVAS_META_KEYS.LAST_POS_X] = mouseEvent.clientX;
    canvasData[CANVAS_META_KEYS.LAST_POS_Y] = mouseEvent.clientY;
  }

  private _onPanMove(event: fabric.TPointerEventInfo) {
    const mouseEvent = event.e as MouseEvent;
    const canvasData = this.canvas.get("data");
    const vpt = this.canvas.viewportTransform;

    vpt[4] += (mouseEvent.clientX - canvasData[CANVAS_META_KEYS.LAST_POS_X]) * 1.5;
    vpt[5] += (mouseEvent.clientY - canvasData[CANVAS_META_KEYS.LAST_POS_Y]) * 1.5;

    this.canvas.setCursor("grabbing");
    this.canvas.requestRenderAll();
    canvasData[CANVAS_META_KEYS.LAST_POS_X] = mouseEvent.clientX;
    canvasData[CANVAS_META_KEYS.LAST_POS_Y] = mouseEvent.clientY;
  }

  private _onEndPanMove() {
    this.canvas.setViewportTransform(this.canvas.viewportTransform);
  }

  private _getMouseDownEventAction(event: fabric.TPointerEventInfo) {
    const mouseEvent = event.e as MouseEvent;

    return {
      isPan: !mouseEvent.ctrlKey && !mouseEvent.shiftKey && !mouseEvent.metaKey && !event.target,
      isDrawing: mouseEvent.ctrlKey,
      isSelecting: mouseEvent.shiftKey,
    };
  }

  private _getMouseMoveEventAction() {
    const canvasData = this.canvas.get("data");

    return {
      isPan: canvasData[CANVAS_META_KEYS.IS_DRAGGING],
      isDrawing: canvasData[CANVAS_META_KEYS.IS_DRAWING],
    };
  }

  private _getMouseUpEventAction() {
    const canvasData = this.canvas.get("data");

    return {
      isPan: canvasData[CANVAS_META_KEYS.IS_DRAGGING],
      isDrawing: canvasData[CANVAS_META_KEYS.IS_DRAWING],
    };
  }

  private _onDeleteButtonPress() {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject instanceof Annotation || activeObject instanceof fabric.ActiveSelection) {
      this.deleteObject(activeObject);
    }
  }

  private _onMoveButtonPress({ x, y }: { x: number; y: number }) {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject instanceof Annotation) {
      const { left, top } = activeObject;
      activeObject.set({ left: left + x, top: top + y });
      this.canvas.renderAll();
    }
  }

  private _keyDownHandler(event: KeyboardEvent) {
    if (event.key === "Backspace" || event.key === "Delete" || event.code === "KeyR") {
      this._onDeleteButtonPress();
    }

    const moveKeys = {
      KeyJ: { x: -2, y: 0 },
      KeyL: { x: 2, y: 0 },
      KeyI: { x: 0, y: -2 },
      KeyK: { x: 0, y: 2 },
    };

    if (event.code in moveKeys) {
      this._onMoveButtonPress(moveKeys[event.code as keyof typeof moveKeys]);
    }
  }

  async dispose() {
    this.cleanListeners.forEach((cleanListener) => cleanListener());
    await this.canvas.dispose();
  }

  addContextMenuFeature() {
    this.canvas.on("contextmenu", (e) => {
      const event = e as fabric.TPointerEventInfo<MouseEvent>;
      event.e.preventDefault();
      event.e.stopPropagation();

      const pointer = this.canvas.getViewportPoint(event.e);

      const target = event.target;

      if (!target || target.get("data")?.isNew) {
        return;
      }

      if (!(target instanceof Annotation) && target instanceof fabric.Group) {
        const objects = target.getObjects();

        this.eventEmitter.emit("contextmenu", {
          objects: markRaw(objects),
          x: pointer.x,
          y: pointer.y,
        });
      } else {
        this.canvas.setActiveObject(target);
        this.canvas.renderAll();

        this.eventEmitter.emit("contextmenu", {
          objects: [markRaw(target)],
          x: pointer.x,
          y: pointer.y,
        });
      }
    });
  }

  disableSelection() {
    this.canvas.selection = false;
  }

  enableSelection() {
    this.canvas.selection = true;
  }

  addMouseEventsFeature() {
    const canvasData = this.canvas.get("data");

    this.canvas.on("mouse:down", (event) => {
      const { isPan, isDrawing, isSelecting } = this._getMouseDownEventAction(event);

      if (!isSelecting) {
        this.disableSelection();
      }

      if (isPan) {
        canvasData[CANVAS_META_KEYS.IS_DRAGGING] = true;
        this._onStartPanMove(event);
      } else if (isDrawing) {
        canvasData[CANVAS_META_KEYS.IS_DRAWING] = true;
        this._onStartRectDraw(event);
      }
    });

    this.canvas.on("mouse:move", (event) => {
      const { isPan, isDrawing } = this._getMouseMoveEventAction();

      if (isPan) {
        this._onPanMove(event);
      } else if (isDrawing) {
        this._onRectDraw(event);
      }
    });

    this.canvas.on("mouse:up", () => {
      const { isPan, isDrawing } = this._getMouseUpEventAction();

      if (isPan) {
        canvasData[CANVAS_META_KEYS.IS_DRAGGING] = false;
        this._onEndPanMove();
      } else if (isDrawing) {
        canvasData[CANVAS_META_KEYS.IS_DRAWING] = false;
        this._onEndRectDraw();
      }

      this.enableSelection();
    });
  }

  addKeyboardEventsFeatures() {
    const keyDownEventHandler = this._keyDownHandler.bind(this);

    window.document.addEventListener("keydown", keyDownEventHandler);

    const removeKeyboardEventsFeature = () => {
      window.document.removeEventListener("keydown", keyDownEventHandler);
    };

    this.cleanListeners.push(removeKeyboardEventsFeature);
  }

  addMouseWheelFeature() {
    this.canvas.on("mouse:wheel", (event) => this._handleCanvasZoom(event));
  }

  rerender() {
    this.canvas.renderAll();
  }

  clearCanvas() {
    this.canvas.getObjects().forEach((object) => {
      if (object instanceof Annotation) {
        this.deleteObject(object);
      }
    });

    this.canvas.renderAll();
  }

  setCanvasSize(htmlImage: HTMLImageElement) {
    const paddingFactor = 0.1;
    const canvasSizeRatio = this.canvas.width / this.canvas.height;
    const imageSizeRatio = htmlImage.width / htmlImage.height;
    const imageSize = {
      left: 0,
      top: 0,
      scale: 0,
    };

    if (canvasSizeRatio <= imageSizeRatio) {
      imageSize.scale = this.canvas.width / (htmlImage.width * (1 + paddingFactor * 2));
      imageSize.left = htmlImage.width * paddingFactor;
      imageSize.top = (this.canvas.height / imageSize.scale - htmlImage.height) / 2;
    } else {
      imageSize.scale = this.canvas.height / (htmlImage.height * (1 + paddingFactor * 2));
      imageSize.left = (this.canvas.width / imageSize.scale - htmlImage.width) / 2;
      imageSize.top = htmlImage.height * paddingFactor;
    }

    const backgroundImage = new fabric.Image(htmlImage, {
      left: imageSize.left,
      top: imageSize.top,
      selectable: false,
      evented: false,
      data: { type: "backgroundImage" },
    });

    this.canvas.add(backgroundImage);
    this.canvas.setZoom(imageSize.scale);
    this.canvas.renderAll();
    this.canvas.get("data").originalScale = imageSize.scale;
  }

  deleteObject(object: Annotation | Annotation[] | fabric.ActiveSelection) {
    const annotations: Annotation[] = [];

    if (object instanceof Annotation) {
      annotations.push(object);
    }

    if (Array.isArray(object)) {
      annotations.push(...object);
    }

    if (annotations.length) {
      annotations.forEach((annotation) => {
        annotation.forEachObject((obj) => this.canvas.remove(obj));
        this.canvas.remove(annotation);
      });

      this.canvas.renderAll();
      this.eventEmitter.emit("annotation:deleted", annotations);

      return;
    }

    if (object instanceof fabric.ActiveSelection) {
      object.getObjects().forEach((obj) => {
        this.deleteObject(obj as Annotation);
      });
    } else {
      this.canvas.remove(object as fabric.Object);
    }

    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  changeAnnotationColor(annotation: Annotation, color: GroupColor) {
    const background = annotation.item(0);
    const text = annotation.item(1);

    background.set({
      fill: color.transparent,
      stroke: color.main,
    });

    text?.set({
      backgroundColor: color.main,
    });

    annotation.set({
      cornerColor: color.main,
      borderColor: color.main,
      cornerStrokeColor: color.main,
    });

    this.canvas.renderAll();
  }

  getActiveAnnotations() {
    const annotations = this.canvas
      .getActiveObjects()
      .filter((object) => object instanceof Annotation);

    return annotations as Annotation[];
  }

  getSelectionCoords() {
    const selection = this.canvas.getActiveObject();
    if (!selection) {
      return null;
    }

    const { left, top, width, height } = selection;
    return { left, top, width, height };
  }

  selectAnnotations(annotations: Annotation[]) {
    const activeSelection = new fabric.ActiveSelection(annotations, {
      canvas: this.canvas,
    });

    this.canvas.setActiveObject(activeSelection);
    this.canvas.renderAll();
  }

  getBackgroundPosition() {
    const backgroundImage = this.canvas
      .getObjects()
      .find((object) => object.get("data")?.type === "backgroundImage") as fabric.Image;

    return {
      left: backgroundImage.left,
      top: backgroundImage.top,
      width: backgroundImage.width,
      height: backgroundImage.height,
    };
  }

  renderAnnotations(annotations: Annotation[]) {
    const zoom = this.canvas.getZoom();

    annotations.forEach((annotation) => {
      annotation.item(1).scale(1 / zoom);
      this.canvas.add(annotation);
    });

    this.canvas.renderAll();
  }

  initAnnotation(preAnnotation: Sequence["frames"][0]["annotations"][0], color: GroupColor) {
    const backgroundPosition = this.getBackgroundPosition();

    const left = backgroundPosition.left + preAnnotation.bbox[0] * backgroundPosition.width;
    const top = backgroundPosition.top + preAnnotation.bbox[1] * backgroundPosition.height;
    const width = (preAnnotation.bbox[2] - preAnnotation.bbox[0]) * backgroundPosition.width;
    const height = (preAnnotation.bbox[3] - preAnnotation.bbox[1]) * backgroundPosition.height;

    const annotation = new Annotation({
      left,
      top,
      width,
      height,
      data: {
        isNew: false,
        type: "annotation",
        originalX: left,
        originalY: top,
        labels: {
          passive: preAnnotation.passive,
          support: preAnnotation.support,
        },
      },
    });

    this.canvas.add(annotation);
    this.addAnnotationRect(annotation);
    this.changeAnnotationColor(annotation, color);

    return annotation;
  }

  renderTextBboxes(bboxes: { bbox: number[]; text: string; color: GroupColor }[]) {
    const existingTextBoxes = this.canvas
      .getObjects()
      .filter((object) => object.get("data")?.type === "pct_bbox");

    existingTextBoxes.forEach((object) => {
      this.canvas.remove(object);
    });

    const zoom = this.canvas.getZoom();
    const backgroundPosition = this.getBackgroundPosition();

    bboxes.forEach(({ bbox, text, color }) => {
      const left = backgroundPosition.left + bbox[0] * backgroundPosition.width;
      const top = backgroundPosition.top + bbox[1] * backgroundPosition.height;
      const width = (bbox[2] - bbox[0]) * backgroundPosition.width;
      const height = (bbox[3] - bbox[1]) * backgroundPosition.height;

      const bboxObject = new fabric.Rect({
        left,
        top,
        width,
        height,
        selectable: false,
        evented: false,
        fill: color.transparentLight,
        stroke: color.main,
        strokeWidth: 4,
      });

      const textObject = new fabric.Text(text, {
        left: left + width / 2,
        top: top + height / 2,
        fontSize: 14,
        strokeWidth: 4,
        stroke: "#000",
        fill: "#fff",
        fontFamily: "arial",
        fontWeight: "bold",
        paintFirst: "stroke",
        padding: 8,
        backgroundColor: color.main,
        evented: false,
        selectable: false,
      });

      textObject.scale(1 / zoom);

      const bboxGroup = new fabric.Group([bboxObject, textObject], {
        canvas: this.canvas,
        selectable: false,
        evented: false,
        data: { type: "pct_bbox" },
      });

      this.canvas.add(bboxGroup);
    });

    this.canvas.renderAll();
  }

  addAnnotationRect(annotation: Annotation) {
    const rectangle = new fabric.Rect({
      left: annotation.left,
      top: annotation.top,
      width: annotation.width,
      height: annotation.height,
    });

    annotation.add(rectangle);

    this.canvas.renderAll();
  }

  addAnnotationText(annotation: Annotation, textContent: string, color: GroupColor) {
    const zoom = this.canvas.getZoom();

    const annotationCenter = annotation.getCenterPoint();
    const background = annotation.item(0);

    const text = new fabric.Text(textContent, {
      left: annotationCenter.x,
      top: annotationCenter.y,
      fontSize: 14,
      strokeWidth: 4,
      stroke: "#000",
      fill: "#fff",
      fontFamily: "arial",
      fontWeight: "bold",
      paintFirst: "stroke",
      padding: 8,
      backgroundColor: color.main,
      evented: false,
      selectable: false,
    });

    text.scale(1 / zoom);
    annotation.add(text);
    annotation.set({ height: background.height, width: background.width });
    text.set({ left: background.width * 0.2 });
    background.set({
      left: ((background.width ? background.width : 0) / 2) * -1,
      top: ((background.height ? background.height : 0) / 2) * -1,
    });

    annotation.setCoords();
    this.canvas.bringObjectToFront(text);
    this.canvas.renderAll();
  }

  updateAnnotationText(annotation: Annotation, textContent: string) {
    const text = annotation.item(1) as fabric.Text;
    if (!text) {
      return;
    }

    text.set({ text: textContent });

    this.canvas.renderAll();
  }

  highlightAnnotations(annotations: Annotation[]) {
    const allObjects = this.canvas.getObjects();

    allObjects.forEach((object) => {
      if (!(object instanceof Annotation)) {
        return;
      }

      const opacity = annotations.includes(object) ? 1 : 0.7;
      object.set("opacity", opacity);
    });

    this.canvas.renderAll();
  }

  revertAnnotationsHighlight() {
    const allObjects = this.canvas.getObjects();

    allObjects.forEach((object) => {
      if (!(object instanceof Annotation)) {
        return;
      }

      object.set("opacity", 1);
    });

    this.canvas.renderAll();
  }

  // https://github.com/microsoft/TypeScript/issues/32205
  // eslint-disable-next-line
  on(eventName: CanvasEvents, listener: (...args: any[]) => void) {
    this.eventEmitter.on(eventName, listener);
  }
}
