import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import logger from "shared/services/logger";
import SequenceRepository from "@/repositories/SequenceRepository";
import { SequenceQuery, SequenceSummary } from "@/views/person_gad/types";

export const usePersonGadSequences = (query: Ref<SequenceQuery>) => {
  const { data, isLoading, error, refetch } = useQuery<SequenceSummary>({
    queryKey: ["sequences", query],
    queryFn: async () => {
      return await SequenceRepository.loadSequences(query.value);
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const sequences = computed(
    () =>
      data.value || {
        data: [],
        total_count: 0,
        completed_count: 0,
        approved_count: 0,
        processes_count: 0,
        bbox_count: 0,
      },
  );

  return { sequences, isLoading, error, refetch };
};
