import { v4 as uuid } from "uuid";
import { markRaw, ref } from "vue";
import getProcessClasses from "shared/constants/ProcessClasses";
import { DecodedLabel } from "shared/types/ProcessClass";
import SequenceRepository from "@/repositories/SequenceRepository";
import { Sequence, SequenceExport } from "@/views/person_gad/types";
import { Annotation } from "../Annotation";
import { GROUP_COLORS, GroupColor } from "../colors";
import CanvasService from "./canvasService";

export type GroupProcessClasses = DecodedLabel | "no_class";

export type AnnotationGroup = {
  id: string;
  groupId: number;
  processClass: GroupProcessClasses;
  color: GroupColor;
  annotations: Annotation[];
};

type SequenceGroup = Sequence["groups"][number];
type ProcessBbox = Sequence["frames"][0]["process_bboxes"][0] & { visible: boolean };

const processClasses = getProcessClasses();

export default class GroupService {
  MAX_FRAMES_NUMBER = 15;

  private cleanListeners: (() => void)[] = [];
  private cleanSaveInterval = () => {};
  private _frameIndex = ref(0);
  private showAnnotationClassText = false;
  private _isSequenceSaving = ref(false);
  private _sequenceProcessBboxes = ref<ProcessBbox[][]>([]);
  private readonly _sequenceGroups = ref<SequenceGroup[]>([]);
  private readonly allFrameGroups = ref<AnnotationGroup[][]>([]);
  private readonly canvasService: CanvasService;
  private readonly _sequence = ref<Sequence>({} as Sequence);

  constructor(canvasService: CanvasService, sequence: Sequence) {
    this.canvasService = canvasService;
    this._sequence.value = sequence;
    this._sequenceGroups.value = sequence.groups.map((group) => ({ ...group }));

    this.initProcessBboxes(sequence);
    this.initFrameGroups(sequence);
    this.setFrameIndex(0);
    this.initAutoSave();

    this.canvasService.on("annotation:created", this._handleAnnotationCreation.bind(this));
    this.canvasService.on("annotation:deleted", this._removeAnnotationsFromGroups.bind(this));
  }

  get currentFrameGroups() {
    return this.allFrameGroups.value[this._frameIndex.value] as AnnotationGroup[];
  }

  get sequenceGroups() {
    return this._sequenceGroups.value as SequenceGroup[];
  }

  get frameIndex() {
    return this._frameIndex.value;
  }

  get sequence() {
    return this._sequence.value;
  }

  get processBboxes() {
    return this._sequenceProcessBboxes.value[this._frameIndex.value];
  }

  get isSequenceSaving() {
    return this._isSequenceSaving.value;
  }

  private _generateId() {
    return uuid();
  }

  private _getSequenceGroupByGroupId(groupId: number) {
    if (groupId === 0) {
      return {
        group_id: 0,
        process_class: "no_class" as GroupProcessClasses,
      };
    }

    const group = this.sequenceGroups.find((group) => group.group_id === groupId);
    return group as Sequence["groups"][number];
  }

  private _removeAnnotationsFromGroups(annotations: Annotation[]) {
    this.currentFrameGroups.forEach((group) => {
      group.annotations = group.annotations.filter((groupAnnotation) => {
        return !annotations.includes(groupAnnotation);
      });
    });
  }

  private _handleAnnotationCreation(annotation: Annotation) {
    this.canvasService.addAnnotationRect(annotation);

    const siblingGroup = this._getSiblingGroup(annotation);
    let group: AnnotationGroup;

    if (siblingGroup) {
      group = this.addAnnotationsToGroup(siblingGroup, annotation);
    } else {
      const noClassGroup = this.currentFrameGroups.find((group) => {
        return group.processClass === "no_class";
      }) as AnnotationGroup;

      group = this.addAnnotationsToGroup(noClassGroup, annotation);
    }

    const prefix = group.processClass === "no_class" ? "" : `#${group.groupId} `;

    this.canvasService.addAnnotationText(annotation, `${prefix}${group.processClass}`, group.color);
  }

  private _getDistanceBetweenAnnotations(annotation1: Annotation, annotation2: Annotation) {
    const annotation1CenterX = annotation1.left + annotation1.width / 2;
    const annotation1CenterY = annotation1.top + annotation1.height / 2;
    const annotation2CenterX = annotation2.left + annotation2.width / 2;
    const annotation2CenterY = annotation2.top + annotation2.height / 2;

    const clearHorizontalSpacing =
      Math.abs(annotation1CenterX - annotation2CenterX) -
      (annotation1.width + annotation2.width) / 2;
    const clearVerticalSpacing =
      Math.abs(annotation1CenterY - annotation2CenterY) -
      (annotation1.height + annotation2.height) / 2;

    return Math.max(clearHorizontalSpacing, clearVerticalSpacing, 0);
  }

  private _getGroupDensity(annotations: Annotation[]) {
    let totalDistance = 0;
    let comparisons = 0;

    for (let i = 0; i < annotations.length; i++) {
      for (let j = i + 1; j < annotations.length; j++) {
        totalDistance += this._getDistanceBetweenAnnotations(annotations[i], annotations[j]);
        comparisons++;
      }
    }

    const averageDistance = totalDistance / comparisons;
    return averageDistance;
  }

  private _getSiblingGroup(annotation: Annotation) {
    const PROXIMITY_THRESHOLD = 50;
    const DENSITY_THRESHOLD = 50;

    const annotationGroup = this._getGroupByAnnotation(annotation);
    const differentGroups = this.currentFrameGroups.filter((group) => group !== annotationGroup);

    let closestGroup = undefined;
    let closestDistance = Infinity;

    differentGroups.forEach((group) => {
      group.annotations.forEach((groupObj) => {
        const distance = this._getDistanceBetweenAnnotations(annotation, groupObj);
        const isClosest = distance < closestDistance && distance <= PROXIMITY_THRESHOLD;

        if (isClosest) {
          const isDense = this._getGroupDensity(group.annotations) <= DENSITY_THRESHOLD;

          if (isDense) {
            closestDistance = distance;
            closestGroup = group;
          }
        }
      });
    });

    return closestGroup;
  }

  private _findAnnotationsInSelection(selectionCoords: {
    left: number;
    top: number;
    width: number;
    height: number;
  }) {
    const annotations = this.currentFrameGroups.flatMap((group) => group.annotations);

    return annotations.filter((annotation) => {
      const annotationCoords = {
        left: annotation.left,
        top: annotation.top,
        width: annotation.width,
        height: annotation.height,
      };

      const isAnnotationInSelection =
        selectionCoords.left < annotationCoords.left + annotationCoords.width &&
        selectionCoords.left + selectionCoords.width > annotationCoords.left &&
        selectionCoords.top < annotationCoords.top + annotationCoords.height &&
        selectionCoords.top + selectionCoords.height > annotationCoords.top;

      return isAnnotationInSelection;
    });
  }

  private _getGroupByAnnotation(annotation: Annotation) {
    return this.currentFrameGroups.find((group) => group.annotations.includes(annotation));
  }

  private _generateAnnotationText(annotation: Annotation, annotationGroup?: AnnotationGroup) {
    const group = annotationGroup || this._getGroupByAnnotation(annotation);

    let prefix = "";
    if (annotation.data.labels.support) {
      prefix += "S";
    }
    if (annotation.data.labels.passive) {
      prefix += "P";
    }

    if (prefix.length) {
      prefix = `(${prefix}) `;
    }

    prefix += group?.processClass === "no_class" ? "no_class" : `#${group?.groupId}`;

    if (this.showAnnotationClassText && group?.processClass !== "no_class") {
      return `${prefix} ${group?.processClass}`;
    }

    return prefix;
  }

  private _keyboardLabelHandler(event: KeyboardEvent) {
    const keyCodeMap = {
      KeyF: "support",
      KeyP: "passive",
    } as const;
    const key = event.code as keyof typeof keyCodeMap;

    if (keyCodeMap[key]) {
      this.updateLabelToAnnotations(keyCodeMap[key]);
    }
  }

  private _keyboardToggleTextboxHandler(event: KeyboardEvent) {
    if (event.code !== "KeyH") {
      return;
    }

    this.showAnnotationClassText = !this.showAnnotationClassText;

    this.allFrameGroups.value.forEach((frameGroups) => {
      frameGroups.forEach((group) => {
        group.annotations.forEach((annotation) => {
          this.canvasService.updateAnnotationText(
            annotation as Annotation,
            this._generateAnnotationText(annotation as Annotation, group as AnnotationGroup),
          );
        });
      });
    });
  }

  private _keyboardGroupHandler(event: KeyboardEvent) {
    const activeAnnotations = this.canvasService?.getActiveAnnotations();

    const isAnnotationSelected = !!activeAnnotations.length;
    const isNoClassGroup = event.key === "0" || event.code === "KeyW";
    const isSequenceGroup = this.sequenceGroups.some((g) => g.group_id === Number(event.key));
    const isGroupExists = isNoClassGroup || isSequenceGroup;

    if (!isAnnotationSelected || !isGroupExists) {
      return;
    }

    let group: AnnotationGroup;

    if (isNoClassGroup) {
      group = this.currentFrameGroups.find((group) => {
        return group.processClass === "no_class";
      }) as AnnotationGroup;
    } else {
      group = this.currentFrameGroups.find((group) => {
        return group.groupId === Number(event.key);
      }) as AnnotationGroup;

      if (!group) {
        const sequenceGroup = this._getSequenceGroupByGroupId(Number(event.key));
        group = this.createGroup(sequenceGroup.process_class, sequenceGroup.group_id);
      }
    }

    this.addAnnotationsToGroup(group, activeAnnotations);
  }

  private async _keyboardSaveHandler(event: KeyboardEvent) {
    if (event.key === "s" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      await this.saveAnnotations();
    }
  }

  private _keyboardSequenceImageSwitchHandler(event: KeyboardEvent) {
    const isArrowKey = ["ArrowLeft", "ArrowRight"].includes(event.key);
    const isADKey = ["KeyA", "KeyD"].includes(event.code);

    if (!isArrowKey && !isADKey) {
      return;
    }

    const isLeftArrow = event.key === "ArrowLeft" || event.code === "KeyA";
    const isRightArrow = event.key === "ArrowRight" || event.code === "KeyD";
    let newFrame = 0;

    if (isLeftArrow) {
      newFrame = (this.frameIndex - 1 + this.MAX_FRAMES_NUMBER) % this.MAX_FRAMES_NUMBER;
    } else if (isRightArrow) {
      newFrame = (this.frameIndex + 1) % this.MAX_FRAMES_NUMBER;
    }

    this.setFrameIndex(newFrame);
  }

  dispose() {
    this.cleanListeners.forEach((cleanListener) => cleanListener());
    this.cleanSaveInterval();
  }

  addKeyboardFeatures() {
    const eventHandler = async (event: KeyboardEvent) => {
      this._keyboardGroupHandler(event);
      this._keyboardLabelHandler(event);
      this._keyboardToggleTextboxHandler(event);
      this._keyboardSequenceImageSwitchHandler(event);
      await this._keyboardSaveHandler(event);
    };

    const removeEventListener = () => {
      window.removeEventListener("keydown", eventHandler);
    };

    window.addEventListener("keydown", eventHandler);

    this.cleanListeners.push(removeEventListener);
  }

  setFrameIndex(index: number) {
    if (index === this._frameIndex.value) {
      return;
    }

    this._frameIndex.value = index;
    const selectionCoords = this.canvasService.getSelectionCoords();
    this.canvasService.clearCanvas();
    this.renderProcessBboxes();
    this.canvasService.renderAnnotations(
      this.currentFrameGroups.flatMap((group) => group.annotations),
    );

    if (selectionCoords) {
      const annotationsInSelection = this._findAnnotationsInSelection(selectionCoords);
      this.canvasService.selectAnnotations(annotationsInSelection);
    }
  }

  getProcessClassColor(processClass: GroupProcessClasses = "no_class") {
    const processClassIndex = processClasses.findIndex((process) => {
      return process.decodedLabel === processClass;
    });

    const color = GROUP_COLORS[Math.abs(processClassIndex - 1) % GROUP_COLORS.length];

    return color;
  }

  isSequencesChanged(sequenceDump: SequenceExport, ignoreSaveStatus = false) {
    if (this.sequence.status === "incomplete" && !ignoreSaveStatus) {
      return true;
    }

    const annotationMapper = (annotation: SequenceExport["frames"][0]["annotations"][0]) => ({
      bbox: annotation.bbox,
      group_id: annotation.group_id,
    });

    const isFramesEqual = this.sequence.frames.every((frame, index) => {
      const dumpFrame = sequenceDump.frames[index];
      const dumpFrameAnnotations = dumpFrame.annotations.map(annotationMapper);
      const frameAnnotations = frame.annotations.map(annotationMapper);

      return JSON.stringify(dumpFrameAnnotations) === JSON.stringify(frameAnnotations);
    });

    const mappedSavedSequenceGroups = this.sequence.groups.map((group) => ({
      ...group,
      process_class: SequenceRepository.decodedLabelToEncodedLabel(group.process_class),
    }));

    const isGroupsEqual =
      JSON.stringify(mappedSavedSequenceGroups) === JSON.stringify(sequenceDump.groups);

    return !isFramesEqual || !isGroupsEqual;
  }

  initProcessBboxes(sequence: Sequence) {
    this._sequenceProcessBboxes.value = sequence.frames.map((frame) => {
      return frame.process_bboxes.map((bbox) => ({
        ...bbox,
        visible: true,
      }));
    });
  }

  initFrameGroups(sequence: Sequence) {
    sequence.frames.forEach((frame, index) => {
      if (index >= this.allFrameGroups.value.length) {
        this.allFrameGroups.value.push([]);
      }

      this.setFrameIndex(index);

      if (!frame.annotations.some((group) => group.group_id === 0)) {
        this.createGroup("no_class", 0);
      }

      frame.annotations.forEach((frameAnnotation) => {
        const sequenceGroup = this._getSequenceGroupByGroupId(frameAnnotation.group_id);

        let frameGroup = this.currentFrameGroups.find(
          (group) => group.groupId === frameAnnotation.group_id,
        );

        if (!frameGroup) {
          frameGroup = this.createGroup(sequenceGroup.process_class, sequenceGroup.group_id);
        }

        const annotation = markRaw(
          this.canvasService.initAnnotation(frameAnnotation, frameGroup.color),
        );

        const annotationText = this._generateAnnotationText(annotation, frameGroup);
        this.canvasService.addAnnotationText(annotation, annotationText, frameGroup.color);
        frameGroup.annotations.push(annotation);
      });
    });
  }

  initAutoSave() {
    const interval = setInterval(async () => {
      await this.saveAnnotations();
    }, 1000 * 60 * 3);

    const removeInterval = () => {
      clearInterval(interval);
    };

    this.cleanSaveInterval = removeInterval;
  }

  updateLabelToAnnotations(label: "support" | "passive") {
    const noClassGroup = this.currentFrameGroups.find((group) => group.processClass === "no_class");
    const activeAnnotations = this.canvasService
      ?.getActiveAnnotations()
      .filter((annotation) => !noClassGroup?.annotations.includes(annotation));

    if (!activeAnnotations?.length) {
      return;
    }

    const allAnnotationsHaveSameLabelValue = activeAnnotations.every((annotation) => {
      return annotation.data.labels[label] === activeAnnotations[0].data.labels[label];
    });

    if (allAnnotationsHaveSameLabelValue) {
      activeAnnotations.forEach((annotation) => {
        annotation.data.labels[label] = !annotation.data.labels[label];
        this.canvasService.updateAnnotationText(
          annotation,
          this._generateAnnotationText(annotation),
        );
      });

      return;
    }

    activeAnnotations.forEach((annotation) => {
      annotation.data.labels[label] = true;
      this.canvasService.updateAnnotationText(annotation, this._generateAnnotationText(annotation));
    });
  }

  createGroup(processClass: GroupProcessClasses, groupId?: number) {
    const color = this.getProcessClassColor(processClass);

    if (!groupId && groupId !== 0) {
      const sortedSequenceGroups = this.sequenceGroups
        .slice()
        .sort((a, b) => a.group_id - b.group_id);
      groupId = (sortedSequenceGroups.at(-1)?.group_id || 0) + 1;
    }

    const group: AnnotationGroup = {
      id: this._generateId(),
      groupId: groupId,
      color: color,
      processClass: processClass,
      annotations: [],
    };

    this.currentFrameGroups.push(group);

    const isGroupExists = this._sequenceGroups.value.some((group) => group.group_id === groupId);
    if (groupId !== 0 && !isGroupExists) {
      this._sequenceGroups.value.push({
        group_id: groupId,
        process_class: processClass,
      });
    }

    return group;
  }

  addAnnotationsToGroup(group: AnnotationGroup, annotation: Annotation | Annotation[]) {
    const isNoClassGroup = group.processClass === "no_class";
    const annotations = Array.isArray(annotation) ? annotation : [annotation];

    if (isNoClassGroup) {
      annotations.forEach((annotation) => {
        annotation.data.labels.support = false;
        annotation.data.labels.passive = false;
      });
    }

    this.currentFrameGroups.forEach((group) => {
      annotations.forEach((annotation) => {
        if (group.annotations.includes(annotation)) {
          group.annotations = group.annotations.filter((item) => item !== annotation);
        }
      });
    });

    group.annotations.push(...annotations.map(markRaw));
    group.annotations.forEach((annotation) => {
      this.canvasService.updateAnnotationText(
        annotation,
        this._generateAnnotationText(annotation, group),
      );
      this.canvasService.changeAnnotationColor(annotation, group.color);
    });

    return group;
  }

  deleteGroup(group: AnnotationGroup) {
    const annotations = group.annotations;
    const noClassGroup = this.currentFrameGroups.find((group) => {
      return group.processClass === "no_class";
    }) as AnnotationGroup;

    this.currentFrameGroups.splice(this.currentFrameGroups.indexOf(group), 1);
    this.addAnnotationsToGroup(noClassGroup, annotations);
  }

  deleteSequenceGroup(group: Sequence["groups"][number]) {
    const isConfirmed = confirm("Are you sure you want to delete this group?");

    if (!isConfirmed) {
      return;
    }

    const currentFrameIndex = this.frameIndex;
    this.allFrameGroups.value.forEach((frameGroups, index) => {
      const groupToDelete = frameGroups.find((frameGroup) => {
        return frameGroup.groupId === group.group_id;
      });

      if (groupToDelete) {
        this.setFrameIndex(index);
        this.deleteGroup(groupToDelete as AnnotationGroup);
      }
    });

    this.setFrameIndex(currentFrameIndex);

    this._sequenceGroups.value = this._sequenceGroups.value.filter((sequenceGroup) => {
      return sequenceGroup.group_id !== group.group_id;
    });

    this._sequenceProcessBboxes.value = this._sequenceProcessBboxes.value.map(
      (frameProcessBboxes) => {
        return frameProcessBboxes.filter((processBbox) => {
          return processBbox.group_id !== group.group_id;
        });
      },
    );
    this.renderProcessBboxes();
  }

  async saveAnnotations(
    saveStatus?: "completed" | "approved" | "incomplete",
    ignoreSaveOnNoChange = false,
  ) {
    const exportDump = this.exportFrameGroups();
    const isSequenceChanged = this.isSequencesChanged(exportDump, ignoreSaveOnNoChange);
    if (!isSequenceChanged && ignoreSaveOnNoChange) {
      return;
    }

    exportDump.status = saveStatus || this.sequence.status;
    this._isSequenceSaving.value = true;

    const updatedSequence = await SequenceRepository.saveSequenceAnnotations(
      this._sequence.value._id,
      exportDump,
    );

    this._isSequenceSaving.value = false;
    this._sequence.value = updatedSequence;
  }

  renderProcessBboxes() {
    const bboxesToRender = this.processBboxes
      .filter((processBboxes) => processBboxes.visible)
      .map((processBboxes) => {
        const sequenceGroup = this.sequenceGroups.find((group) => {
          return group.group_id === processBboxes.group_id;
        });

        return {
          bbox: processBboxes.bbox,
          text: `pct_box - ${sequenceGroup?.process_class}`,
          color: this.getProcessClassColor(sequenceGroup?.process_class),
        };
      });

    this.canvasService.renderTextBboxes(bboxesToRender);
  }

  toggleSequenceGroupVisibility(group: SequenceGroup) {
    this._sequenceProcessBboxes.value = this._sequenceProcessBboxes.value.map(
      (frameProcessBboxes) => {
        return frameProcessBboxes.map((bbox) => {
          if (bbox.group_id === group.group_id) {
            bbox.visible = !bbox.visible;
          }

          return bbox;
        });
      },
    );

    this.renderProcessBboxes();
  }

  getClosestFrameIndexWithGroup(groupId: number, direction: "next" | "prev") {
    const isCurrentGroup = (index: number) => {
      return this.allFrameGroups.value[index].some((group) => group.groupId === groupId);
    };

    if (direction === "next") {
      for (let i = this.frameIndex + 1; i < this.allFrameGroups.value.length; i++) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }

      for (let i = 0; i < this.frameIndex; i++) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }
    }

    if (direction === "prev") {
      for (let i = this.frameIndex - 1; i >= 0; i--) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }

      for (let i = this.allFrameGroups.value.length - 1; i > this.frameIndex; i--) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }
    }

    return this.frameIndex;
  }

  async sendComment(message: string) {
    const updatedSequence = await SequenceRepository.sendComment(this._sequence.value._id, message);
    this._sequence.value = updatedSequence;
  }

  async deleteComment(commendIndex: number) {
    const updatedSequence = await SequenceRepository.deleteComment(
      this._sequence.value._id,
      commendIndex,
    );
    this._sequence.value = updatedSequence;
  }

  exportFrameGroups() {
    const framePosition = this.canvasService.getBackgroundPosition();

    const sequenceGroups = this.sequenceGroups.map((group) => ({
      group_id: group.group_id,
      process_class: SequenceRepository.decodedLabelToEncodedLabel(group.process_class),
    }));

    const frameAnnotations = this.allFrameGroups.value.map((frameGroups, index) => {
      const annotations = frameGroups.flatMap((group) => {
        return group.annotations
          .map((annotation) => {
            const x1 = (annotation.left - framePosition.left) / framePosition.width;
            const y1 = (annotation.top - framePosition.top) / framePosition.height;
            const x2 = x1 + annotation.width / framePosition.width;
            const y2 = y1 + annotation.height / framePosition.height;

            if (x1 >= 1 || y1 >= 1 || x2 <= 0 || y2 <= 0) {
              return;
            }

            return {
              bbox: [
                Math.max(0, Number(x1.toFixed(5))),
                Math.max(0, Number(y1.toFixed(5))),
                Math.min(1, Number(x2.toFixed(5))),
                Math.min(1, Number(y2.toFixed(5))),
              ],
              support: annotation.data.labels.support,
              passive: annotation.data.labels.passive,
              group_id: group.groupId,
            };
          })
          .filter(Boolean) as SequenceExport["frames"][0]["annotations"];
      });

      return {
        annotations,
        frame_id: this._sequence.value.frames[index].frame_id,
        process_bboxes: this._sequenceProcessBboxes.value[index].map((bbox) => ({
          bbox: bbox.bbox,
          group_id: bbox.group_id,
        })),
      };
    });

    return {
      groups: sequenceGroups,
      frames: frameAnnotations,
      status: "incomplete" as "incomplete" | "completed" | "approved",
    };
  }
}
