import { parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import getProcessClasses from "shared/constants/ProcessClasses";
import { apiClient } from "shared/repositories/clients";
import { EncodedLabel } from "shared/types/ProcessClass";
import { GroupProcessClasses } from "@/views/person_gad/services/groupService";
import { Sequence, SequenceExport, SequenceQuery, SequenceSummary } from "@/views/person_gad/types";

const processClasses = getProcessClasses();
const noClassEncodedLabel = 999 as const;

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const mapSequence = (sequence: Sequence<EncodedLabel, string>) => {
  return {
    ...sequence,
    updated: parseUtcDate(sequence.updated),
    groups: sequence.groups.map((group) => ({
      ...group,
      process_class: encodedLabelToDecodedLabel(group.process_class),
    })),
    comments: sequence.comments?.map((comment) => ({
      ...comment,
      created: parseUtcDate(comment.created),
    })),
  };
};

const encodedLabelToDecodedLabel = (encodedLabel: EncodedLabel | 999): GroupProcessClasses => {
  if (encodedLabel === noClassEncodedLabel) {
    return "no_class";
  }

  const processClass = processClasses.find((process) => process.encodedLabel === encodedLabel);
  return processClass?.decodedLabel || "no_class";
};

const decodedLabelToEncodedLabel = (decodedLabel: GroupProcessClasses): EncodedLabel | 999 => {
  if (decodedLabel === "no_class") {
    return noClassEncodedLabel;
  }

  const processClass = processClasses.find((process) => process.decodedLabel === decodedLabel);
  if (processClass === undefined) {
    return noClassEncodedLabel;
  }

  return processClass.encodedLabel;
};

const objectToUrlSearchParams = (object: Record<string, unknown>) => {
  const params = new URLSearchParams();

  Object.entries(object).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach((v) => params.append(key, String(v)));
    } else {
      params.append(key, String(value));
    }
  });

  return params;
};

const loadSequenceAnnotations = async (id: string) => {
  return apiClient
    .get<Sequence<EncodedLabel, string>>(`person-gad/sequence/${id}`)
    .then((data) => mapSequence(data.data));
};

const saveSequenceAnnotations = async (id: string, sequence: SequenceExport) => {
  return apiClient
    .patch<Sequence<EncodedLabel, string>>(`person-gad/sequence/${id}`, sequence)
    .then((data) => mapSequence(data.data));
};

const loadSequences = async (query: SequenceQuery) => {
  return apiClient
    .post<SequenceSummary<EncodedLabel, string>>(`person-gad/sequences`, query)
    .then((response) => ({ ...response.data, data: response.data.data.map(mapSequence) }));
};

const sendComment = async (id: string, message: string) => {
  return apiClient
    .post<Sequence<EncodedLabel, string>>(`person-gad/sequence/${id}/comment`, { message })
    .then((response) => mapSequence(response.data));
};

const deleteComment = async (id: string, commendIndex: number) => {
  return apiClient
    .delete<Sequence<EncodedLabel, string>>(`person-gad/sequence/${id}/comment/${commendIndex}`)
    .then((response) => mapSequence(response.data));
};

const createSequencesDump = async () => {
  return apiClient.post(`person-gad/dump`);
};

export default {
  loadSequenceAnnotations,
  saveSequenceAnnotations,
  loadSequences,
  encodedLabelToDecodedLabel,
  decodedLabelToEncodedLabel,
  createSequencesDump,
  sendComment,
  deleteComment,
  objectToUrlSearchParams,
};
